import React from "react";
import { Box, Typography, Link, Tooltip } from "@mui/material";
import { useSpring, animated } from "react-spring";

import { ReactComponent as MaintenanceSVG } from "../components/animations/Programming.svg";

const Maintenance = () => {
  const animationProps = useSpring({
    from: { opacity: 0, transform: "translateY(-30px)" },
    to: { opacity: 1, transform: "translateY(0px)" },
    config: { duration: 1000 },
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: "1rem",
        backgroundColor: "#000",
      }}
    >
      <animated.div style={animationProps}>
        <Tooltip title="Programming Merit Badge" arrow={true} placement="top">
          <span>
            <div>
              <MaintenanceSVG
                width="100"
                height="100"
                style={{ marginBottom: "1rem" }}
              />
            </div>
          </span>
        </Tooltip>
      </animated.div>
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          marginBottom: "1rem",
        }}
      >
        We're currently down for maintenance and improvements!
      </Typography>
      <Typography
        variant="body1"
        sx={{
          textAlign: "center",
          marginBottom: "2rem",
        }}
      >
        Our team is working hard to bring you a better experience. Check back
        soon!
      </Typography>
      <Link
        href="mailto:jacob@adiumtech.com?subject=MB%20College%20Technical%20Difficulties"
        variant="body1"
        underline="none"
        sx={{
          marginTop: "2rem",
          fontSize: "1.2rem",
          fontWeight: 500,
          color: "#fff",
          transition: "color 0.3s ease",
          "&:hover": {
            color: "#007bff",
          },
        }}
      >
        Contact us
      </Link>
    </Box>
  );
};

export default Maintenance;
