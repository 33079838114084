import { Container, IconButton, Paper, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import InfoIcon from "@mui/icons-material/Info";

import { disableSwipe, sortClassStartTimes } from "../../utils/utils";

export default function BadgeCards(props) {
  const { badges, setIsInfoDialogOpen, setSelectedBadge } = props;
  return (
    <Container>
      {badges.map((badge) => {
        return (
          <Paper key={badge[0].courseId} sx={{ marginBottom: 2 }}>
            <Grid2 container spacing={2} columns={12}>
              <Grid2
                lg={3}
                md={6}
                sm={6}
                xs={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                >
                  <img
                    src={badge[0].imageUrl}
                    alt={badge[0].meritBadgeName}
                    width={50}
                    height={50}
                  />
                  <Typography>{badge[0].meritBadgeName}</Typography>
                </Stack>
              </Grid2>
              <Grid2
                lg={3}
                md={6}
                sm={6}
                xs={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Stack textAlign="left">
                  <Typography noWrap>
                    <strong>Class Times:</strong>
                  </Typography>
                  {sortClassStartTimes(
                    badge.map((session) => {
                      return session.classTimes;
                    }),
                  ).map((time) => {
                    return (
                      <Typography key={time} noWrap variant="body2">
                        {time}
                      </Typography>
                    );
                  })}
                </Stack>
              </Grid2>
              <Grid2
                lg={3}
                md={6}
                sm={6}
                xs={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Stack textAlign="center">
                  <Typography noWrap>
                    <strong>Spots Left:</strong>
                  </Typography>
                  <Typography noWrap variant="body2">
                    {badge.reduce(
                      (n, { spotsRemaining }) => n + spotsRemaining,
                      0,
                    )}
                  </Typography>
                </Stack>
              </Grid2>
              <Grid2
                lg={3}
                md={6}
                sm={6}
                xs={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Stack textAlign="center">
                  <Typography noWrap>
                    <strong>Info:</strong>
                  </Typography>
                  <div>
                    <IconButton
                      sx={{ boxShadow: 3 }}
                      size="small"
                      onClick={() => {
                        disableSwipe();
                        setIsInfoDialogOpen(true);
                        setSelectedBadge(badge);
                      }}
                    >
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </div>
                </Stack>
              </Grid2>
            </Grid2>
          </Paper>
        );
      })}
    </Container>
  );
}
