import * as React from "react";
import { Box, Link, Stack, Typography } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useMediaQuery } from "react-responsive";
import "react-pdf/dist/cjs/Page/TextLayer.css";

import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
export default function Flyer(props) {
  const {
    datastore,
    setHeaderOpen,
    headerOpen,
    headerHeight,
    setHeaderHeight,
    footerHeight,
    setFooterHeight,
  } = props;
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1224 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <>
      <Header
        datastore={datastore}
        headerOpen={headerOpen}
        setHeaderOpen={setHeaderOpen}
        headerHeight={headerHeight}
        setHeaderHeight={setHeaderHeight}
      />
      <Box
        sx={{
          flexDirection: "column",
          backgroundColor: "primary.light",
          minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
        }}
        mt={`${headerHeight}px`}
        pb={1}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={2}
          pt={4}
          sx={{ minHeight: "calc(100vh - 56px - 80px)" }}
        >
          <Document
            file={
              window._env_.REACT_APP_API_URL +
              "/getFile/" +
              datastore.config.configId +
              "/" +
              datastore.config.flyerFileId
            }
          >
            <Page
              renderAnnotationLayer={false}
              pageNumber={1}
              width={
                isBigScreen
                  ? 900
                  : isDesktopOrLaptop
                    ? 800
                    : isTablet
                      ? 600
                      : isMobile
                        ? 300
                        : 200
              }
            />
          </Document>
          <Typography variant="h6" pb={2}>
            Flier:{" "}
            <Link
              href={
                window._env_.REACT_APP_API_URL +
                "/getFile/" +
                datastore.config.configId +
                "/" +
                datastore.config.flyerFileId
              }
            >
              <strong>Download</strong>
            </Link>
          </Typography>
        </Stack>
      </Box>
      <Footer footerHeight={footerHeight} setFooterHeight={setFooterHeight} />
    </>
  );
}
