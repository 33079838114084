import Dialog from "@mui/material/Dialog";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import { useEffect, useState } from "react";
import ky from "ky";

import { capitalize, disableSwipe, enableSwipe } from "../../utils/utils";

export default function VoidRegistrationDialog(props) {
  const {
    open,
    setOpen,
    datastore,
    setOuterAlertLevel,
    setOuterAlertMessage,
    setOuterAlertIsOpen,
    setIsLoading,
  } = props;

  const [allScouts, setAllScouts] = useState([]);
  const [selectedScout, setSelectedScout] = useState({
    participantId: "Select",
  });
  const [isRefund, setIsRefund] = useState(false);
  const [isAmountChanged, setIsAmountChanged] = useState(false);
  const [refundAmount, setRefundAmount] = useState(datastore.config.eventPrice);
  const [refundMessage, setRefundMessage] = useState("");

  const isPositiveFloat = (value) => {
    let floatRegex = /^(?!0$)(?!0[.,]0*$)-?\d+(?:[.,]\d*?)?$/;
    if (!floatRegex.test(value)) return false;

    value = parseFloat(value);
    if (isNaN(value)) return false;

    return value >= 0;
  };

  useEffect(() => {
    const fetchScouts = () => {
      return ky
        .get(
          window._env_.REACT_APP_API_URL +
            "/participants/" +
            datastore.config.configId,
          {
            timeout: window._env_.REACT_APP_TIMEOUT,
          },
        )
        .json();
    };

    setIsLoading(true);
    fetchScouts()
      .then((scoutData) => {
        setAllScouts(
          scoutData.filter((scout) => scout.initialRegistrationTime !== null),
        );
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setOuterAlertLevel("error");
        setOuterAlertMessage("Error: Could not fetch scouts.");
        setOuterAlertIsOpen(true);
      });
  }, []);

  const handleScoutSelect = (id) => {
    if (id === "Select") {
      setSelectedScout({
        participantId: "Select",
      });
    } else if (id === null) {
      // Case where the user deletes the text, do nothing
    } else {
      const scout = allScouts.find((scout) => scout.participantId === id);
      setSelectedScout(scout);
      setRefundAmount(parseFloat(scout.paymentAmount));
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={() => {
        enableSwipe();
        setOpen(false);
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h5" noWrap sx={{ flexGrow: 1 }}>
            <strong>Void a Scout's Registration</strong>
          </Typography>
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={() => {
                enableSwipe();
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Select the scout you want to void. Then fill out the appropriate
          fields. Click the button to void the scout's registration. You also
          have the option to refund the scout for any amount you want.
        </Typography>
        <Typography variant="body1" sx={{ paddingTop: "10px" }}>
          <strong>NOTE:</strong> The parent and the scout will be notified of
          these changes! Please be sure to communicate with them before making
          any changes. If you are refunding a scout through here, please make
          sure the Paypal refund was actually initiated.
        </Typography>
        <Box component="form" sx={{ mt: 1 }}>
          <Autocomplete
            options={[{ participantId: "Select" }, ...allScouts]}
            getOptionLabel={(option) => {
              if (option.participantId === "Select")
                return option.participantId;
              else {
                return `${capitalize(option.firstName)} ${capitalize(
                  option.lastName,
                )} - ${option.unitType}: ${option.unitNumber}`;
              }
            }}
            isOptionEqualToValue={(option, value) => {
              // console.log(option, value);
              return option.participantId === value.participantId;
            }}
            value={selectedScout}
            onChange={(event, newValue) => {
              handleScoutSelect(newValue ? newValue.participantId : null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Scout"
                margin="normal"
                fullWidth
                required
              />
            )}
          />
          {selectedScout.participantId !== "Select" ? (
            <>
              <Toolbar>
                <Typography variant="h6" flexGrow={1} align="center">
                  <strong>
                    {capitalize(selectedScout.firstName)}'s Profile Info
                  </strong>
                </Typography>
              </Toolbar>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                    >
                      <strong>Unit</strong>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                    >
                      {selectedScout.unitType} {selectedScout.unitNumber}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <strong>Birthdate</strong>
                    </TableCell>
                    <TableCell align="center">
                      {selectedScout.birthdate}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <strong>Reg Date</strong>
                    </TableCell>
                    <TableCell align="center">
                      {selectedScout.initialRegistrationTime
                        ? new Date(
                            selectedScout.initialRegistrationTime,
                          ).toLocaleString("en-US", {
                            timeZone: "America/Los_Angeles",
                          })
                        : null}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <strong>Payment</strong>
                    </TableCell>
                    <TableCell align="center">
                      {selectedScout.paymentAmount ? (
                        <>
                          ${selectedScout.paymentAmount} by{" "}
                          {selectedScout.paymentMethod}
                        </>
                      ) : null}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <strong>PayPal ID</strong>
                    </TableCell>
                    <TableCell align="center">
                      {selectedScout.captureId}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <strong>Email</strong>
                    </TableCell>
                    <TableCell align="center">
                      {selectedScout.participantEmailAddress
                        ? selectedScout.participantEmailAddress
                        : selectedScout.parentEmailAddress}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Toolbar>
                <Typography variant="h6" flexGrow={1} align="center">
                  <strong>
                    {capitalize(selectedScout.firstName)}'s Classes
                  </strong>
                </Typography>
              </Toolbar>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      <strong>Session 1</strong>
                    </TableCell>
                    <TableCell align="center">
                      {selectedScout.session1BadgeName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <strong>Session 2</strong>
                    </TableCell>
                    <TableCell align="center">
                      {selectedScout.session2BadgeName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <strong>Session 3</strong>
                    </TableCell>
                    <TableCell align="center">
                      {selectedScout.session3BadgeName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <strong>Session 4</strong>
                    </TableCell>
                    <TableCell align="center">
                      {selectedScout.session4BadgeName}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <FormGroup>
                <FormControlLabel
                  sx={{ mt: 1 }}
                  control={
                    <Checkbox
                      sx={{ pl: 2 }}
                      checked={isRefund}
                      onChange={(e) => setIsRefund(e.target.checked)}
                    />
                  }
                  label="Want to refund this scout?"
                />
                {isRefund ? (
                  <>
                    <TextField
                      sx={{ mt: 1 }}
                      label="Refund Amount"
                      type="number"
                      fullWidth
                      value={refundAmount}
                      onChange={(e) => {
                        setIsAmountChanged(true);
                        setRefundAmount(e.target.value);
                      }}
                      error={!isPositiveFloat(refundAmount) && isAmountChanged}
                      helperText="Price must be greater than 0 and less than or equal to amount paid."
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      sx={{ mt: 2 }}
                      label="Refund Message"
                      fullWidth
                      value={refundMessage}
                      onChange={(e) => {
                        setRefundMessage(e.target.value);
                      }}
                      helperText="This is the reason for the refund given to PayPal and the user."
                    />
                  </>
                ) : null}
              </FormGroup>
              <Button
                disabled={
                  selectedScout.initialRegistrationTime === null ||
                  (isRefund &&
                    !isPositiveFloat(refundAmount) &&
                    isAmountChanged) ||
                  (isRefund && !isPositiveFloat(refundAmount))
                }
                onClick={() => {
                  setIsLoading(true);
                  const refundPayload = {
                    participantId: selectedScout.participantId,
                    configId: selectedScout.configId,
                    isRefund,
                    refundAmount: parseFloat(refundAmount),
                    refundMessage,
                  };
                  ky.patch(
                    window._env_.REACT_APP_API_URL +
                      "/void-participant-registration",
                    {
                      json: refundPayload,
                      timeout: window._env_.REACT_APP_TIMEOUT,
                    },
                  )
                    .json()
                    .then(() => {
                      setIsLoading(false);
                      setOuterAlertLevel("success");
                      setOuterAlertMessage("Successfully voided registration!");
                      setOuterAlertIsOpen(true);
                      setOpen(false);
                      disableSwipe();
                    })
                    .catch((err) => {
                      console.log(err);
                      setIsLoading(false);
                      setOuterAlertLevel("error");
                      setOuterAlertMessage("Error voiding registration!");
                      setOuterAlertIsOpen(true);
                      setOpen(false);
                      disableSwipe();
                    });
                }}
                fullWidth
                color="error"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {isRefund
                  ? "Refund and Void Scouts Registration"
                  : "Void Scouts Registration"}
              </Button>
            </>
          ) : null}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
