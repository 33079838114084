import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const countries = [
  {
    name: "United States",
    code: "US",
    states: [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
  },
  {
    name: "Canada",
    code: "CA",
    states: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Northwest Territories",
      "Nova Scotia",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon",
    ],
  },
  {
    name: "Mexico",
    code: "MX",
    states: [
      "Aguascalientes",
      "Baja California",
      "Baja California Sur",
      "Campeche",
      "Chiapas",
      "Chihuahua",
      "Coahuila",
      "Colima",
      "Durango",
      "Guanajuato",
      "Guerrero",
      "Hidalgo",
      "Jalisco",
      "Mexico City",
      "Michoacán",
      "Morelos",
      "Nayarit",
      "Nuevo León",
      "Oaxaca",
      "Puebla",
      "Querétaro",
      "Quintana Roo",
      "San Luis Potosí",
      "Sinaloa",
      "Sonora",
      "Tabasco",
      "Tamaulipas",
      "Tlaxcala",
      "Veracruz",
      "Yucatán",
      "Zacatecas",
    ],
  },
  // Add more countries as needed
];

const AddressAccordion = ({ config, setConfig, onValidationError }) => {
  const [validationErrors, setValidationErrors] = useState({
    houseNumber: false,
    postcode: false,
    addressFields: false,
  });

  useEffect(() => {
    validateAddressInputs();
  }, [config.eventAddress]);

  const handleAddressChange = (e, field) => {
    setConfig({
      ...config,
      eventAddress: {
        ...config.eventAddress,
        [field]: e.target.value,
      },
    });
    setValidationErrors({
      ...validationErrors,
      houseNumber: false,
      postcode: false,
      addressFields: false,
    });
    onValidationError(false);
  };

  const handleCountryChange = (e) => {
    const selectedCountry = countries.find(
      (country) => country.name === e.target.value,
    );

    setConfig({
      ...config,
      eventAddress: {
        ...config.eventAddress,
        country: e.target.value,
        countryCode: selectedCountry?.code || "",
      },
    });
  };

  const validateAddressInputs = () => {
    const { houseNumber, postcode, ...addressFields } = config.eventAddress;
    const isValidHouseNumber = /^\d+$/.test(houseNumber);
    const isValidPostcode = /^\d+$/.test(postcode);
    const isValidAddressFields = Object.entries(addressFields).every(
      ([field, value]) => {
        if (field === "county" || field === "neighbourhood") {
          return true; // Skip validation for county and neighbourhood fields
        }
        return Boolean(value);
      },
    );
    setValidationErrors({
      houseNumber: !isValidHouseNumber,
      postcode: !isValidPostcode,
      addressFields: !isValidAddressFields,
    });

    const isValid =
      isValidHouseNumber && isValidPostcode && isValidAddressFields;
    onValidationError(!isValid);
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Address</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" pb={3}>
            The address section defines the event address. This is used by the
            map and is included in the registration emails. All fields are
            required.
          </Typography>
          <TextField
            sx={{ mb: 2 }}
            name="name"
            label="Name"
            fullWidth
            value={config.eventAddress.name}
            onChange={(e) => handleAddressChange(e, "name")}
            required
            error={validationErrors.addressFields}
            helperText={
              validationErrors.addressFields &&
              "All address fields are required"
            }
          />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                sx={{ mb: 2 }}
                name="houseNumber"
                label="House Number"
                fullWidth
                value={config.eventAddress.houseNumber}
                onChange={(e) => handleAddressChange(e, "houseNumber")}
                required
                error={validationErrors.houseNumber}
                helperText={
                  validationErrors.houseNumber &&
                  "Please enter a valid house number"
                }
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                sx={{ mb: 2 }}
                name="road"
                label="Street Name"
                fullWidth
                value={config.eventAddress.road}
                onChange={(e) => handleAddressChange(e, "road")}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                sx={{ mb: 2 }}
                name="city"
                label="City"
                fullWidth
                value={config.eventAddress.city}
                onChange={(e) => handleAddressChange(e, "city")}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ mb: 2 }} fullWidth required>
                <InputLabel id="state-label">State</InputLabel>
                <Select
                  labelId="state-label"
                  name="state"
                  value={config.eventAddress.state}
                  onChange={(e) => handleAddressChange(e, "state")}
                  fullWidth
                  required
                >
                  {countries
                    .find(
                      (country) => country.name === config.eventAddress.country,
                    )
                    ?.states.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                sx={{ mb: 2 }}
                name="postcode"
                label="Postal Code"
                fullWidth
                value={config.eventAddress.postcode}
                onChange={(e) => handleAddressChange(e, "postcode")}
                required
                error={validationErrors.postcode}
                helperText={
                  validationErrors.postcode &&
                  "Please enter a valid postal code"
                }
              />
            </Grid>
          </Grid>
          <FormControl sx={{ mb: 2 }} fullWidth required>
            <InputLabel id="country-label">Country</InputLabel>
            <Select
              labelId="country-label"
              name="country"
              value={config.eventAddress.country}
              onChange={handleCountryChange}
              fullWidth
              required
            >
              {countries.map((country) => (
                <MenuItem key={country.code} value={country.name}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AddressAccordion;
