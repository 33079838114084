import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import Home from "../views/Home";
import Login from "../views/Login";
import Flyer from "../views/Flyer";
import SignUp from "../views/SignUp";
import { isAdmin, isCounselor, isLoggedIn, isOwner } from "../utils/utils";
import Scouts from "../views/Scouts";
import FAQ from "../views/FAQ";
import Contact from "../views/Contact";
import Register from "../views/Register";
import Settings from "../views/Settings";
import Admin from "../views/Admin";
import Participants from "../views/Participants";
import ResetPasswordPage from "../views/ResetPasswordPage";
import EditRegistration from "../views/EditRegistration";
import Counselors from "../views/Counselors";

export default function RoutingRoutes(props) {
  const {
    datastore,
    headerOpen,
    setHeaderOpen,
    headerHeight,
    setHeaderHeight,
    footerHeight,
    setFooterHeight,
  } = props;
  return (
    <div>
      <Router>
        <Routes>
          <Route
            path="login"
            element={
              <Login
                datastore={datastore}
                headerOpen={headerOpen}
                setHeaderOpen={setHeaderOpen}
                headerHeight={headerHeight}
                setHeaderHeight={setHeaderHeight}
                footerHeight={footerHeight}
                setFooterHeight={setFooterHeight}
              />
            }
          />
          <Route
            path="signup"
            element={
              <SignUp
                datastore={datastore}
                headerOpen={headerOpen}
                setHeaderOpen={setHeaderOpen}
                headerHeight={headerHeight}
                setHeaderHeight={setHeaderHeight}
                footerHeight={footerHeight}
                setFooterHeight={setFooterHeight}
              />
            }
          />
          <Route
            path="contact"
            element={
              <Contact
                datastore={datastore}
                headerOpen={headerOpen}
                setHeaderOpen={setHeaderOpen}
                headerHeight={headerHeight}
                setHeaderHeight={setHeaderHeight}
                footerHeight={footerHeight}
                setFooterHeight={setFooterHeight}
              />
            }
          />
          <Route
            path="faq"
            element={
              <FAQ
                datastore={datastore}
                headerOpen={headerOpen}
                setHeaderOpen={setHeaderOpen}
                headerHeight={headerHeight}
                setHeaderHeight={setHeaderHeight}
                footerHeight={footerHeight}
                setFooterHeight={setFooterHeight}
              />
            }
          />
          <Route
            path="flyer"
            element={
              <Flyer
                datastore={datastore}
                headerOpen={headerOpen}
                setHeaderOpen={setHeaderOpen}
                headerHeight={headerHeight}
                setHeaderHeight={setHeaderHeight}
                footerHeight={footerHeight}
                setFooterHeight={setFooterHeight}
              />
            }
          />
          <Route
            path="register"
            element={
              isLoggedIn() ? (
                <Register
                  datastore={datastore}
                  headerOpen={headerOpen}
                  setHeaderOpen={setHeaderOpen}
                  headerHeight={headerHeight}
                  setHeaderHeight={setHeaderHeight}
                  footerHeight={footerHeight}
                  setFooterHeight={setFooterHeight}
                />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/edit-registration"
            element={
              isLoggedIn() ? (
                <EditRegistration
                  datastore={datastore}
                  headerOpen={headerOpen}
                  setHeaderOpen={setHeaderOpen}
                  headerHeight={headerHeight}
                  setHeaderHeight={setHeaderHeight}
                  footerHeight={footerHeight}
                  setFooterHeight={setFooterHeight}
                />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/scouts"
            element={
              isLoggedIn() ? (
                <Scouts
                  datastore={datastore}
                  headerOpen={headerOpen}
                  setHeaderOpen={setHeaderOpen}
                  headerHeight={headerHeight}
                  setHeaderHeight={setHeaderHeight}
                  footerHeight={footerHeight}
                  setFooterHeight={setFooterHeight}
                />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/settings"
            element={
              isLoggedIn() ? (
                <Settings
                  datastore={datastore}
                  headerOpen={headerOpen}
                  setHeaderOpen={setHeaderOpen}
                  headerHeight={headerHeight}
                  setHeaderHeight={setHeaderHeight}
                  footerHeight={footerHeight}
                  setFooterHeight={setFooterHeight}
                />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/forgotPassword"
            element={
              !isLoggedIn() ? (
                <ResetPasswordPage
                  datastore={datastore}
                  headerOpen={headerOpen}
                  setHeaderOpen={setHeaderOpen}
                  headerHeight={headerHeight}
                  setHeaderHeight={setHeaderHeight}
                  footerHeight={footerHeight}
                  setFooterHeight={setFooterHeight}
                />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/admin"
            element={
              isLoggedIn() && (isAdmin() || isOwner() || isCounselor()) ? (
                <Admin
                  datastore={datastore}
                  headerOpen={headerOpen}
                  setHeaderOpen={setHeaderOpen}
                  headerHeight={headerHeight}
                  setHeaderHeight={setHeaderHeight}
                  footerHeight={footerHeight}
                  setFooterHeight={setFooterHeight}
                />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/participants"
            element={
              isLoggedIn() && (isAdmin() || isOwner() || isCounselor()) ? (
                <Participants
                  datastore={datastore}
                  headerOpen={headerOpen}
                  setHeaderOpen={setHeaderOpen}
                  headerHeight={headerHeight}
                  setHeaderHeight={setHeaderHeight}
                  footerHeight={footerHeight}
                  setFooterHeight={setFooterHeight}
                />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/counselors"
            element={
              isLoggedIn() && (isAdmin() || isOwner() || isCounselor()) ? (
                <Counselors
                  datastore={datastore}
                  headerOpen={headerOpen}
                  setHeaderOpen={setHeaderOpen}
                  headerHeight={headerHeight}
                  setHeaderHeight={setHeaderHeight}
                  footerHeight={footerHeight}
                  setFooterHeight={setFooterHeight}
                />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          {/* This path must be last*/}
          <Route
            path="/"
            element={
              <Home
                datastore={datastore}
                headerOpen={headerOpen}
                setHeaderOpen={setHeaderOpen}
                headerHeight={headerHeight}
                setHeaderHeight={setHeaderHeight}
                footerHeight={footerHeight}
                setFooterHeight={setFooterHeight}
              />
            }
          />
        </Routes>
      </Router>
    </div>
  );
}
