import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";

import { disableSwipe } from "../../utils/utils";

export default function AddScoutCard(props) {
  const { setAddScoutIsOpen, maxWidth, maxHeight, scoutNum } = props;
  return (
    <Card
      sx={{
        minWidth: 275,
        maxWidth: maxWidth ? maxWidth : null,
        height: maxHeight ? maxHeight : "100%",
      }}
    >
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Typography variant="h5" component="div" align="center">
            Add Scout
          </Typography>
          <Box pt={2}>
            <Tooltip arrow title="Click here to add another scout!">
              <IconButton
                onClick={() => {
                  disableSwipe();
                  setAddScoutIsOpen(true);
                }}
              >
                <AddIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
          {scoutNum === 0 ? (
            <Typography variant="body2" component="div" align="center" pt={2}>
              Please click the "+" icon above to add a profile for your Scout.
              This will allow you to be able to enroll them in their merit badge
              classes.
            </Typography>
          ) : null}
        </Stack>
      </CardContent>
    </Card>
  );
}
