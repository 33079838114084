import { Box, Typography } from "@mui/material";
import * as React from "react";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

export default function Settings(props) {
  const {
    datastore,
    setHeaderOpen,
    headerOpen,
    headerHeight,
    setHeaderHeight,
    footerHeight,
    setFooterHeight,
  } = props;
  return (
    <>
      <Header
        datastore={datastore}
        headerOpen={headerOpen}
        setHeaderOpen={setHeaderOpen}
        headerHeight={headerHeight}
        setHeaderHeight={setHeaderHeight}
      />
      <Box
        sx={{
          backgroundColor: "primary.light",
          minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
        }}
        mt={`${headerHeight}px`}
      >
        <Typography>Settings Page</Typography>
      </Box>
      <Footer footerHeight={footerHeight} setFooterHeight={setFooterHeight} />
    </>
  );
}
