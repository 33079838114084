import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  TextField,
  IconButton,
  Button,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DragHandleIcon from "@mui/icons-material/DragHandle";

const FAQsAccordion = ({ config, setConfig }) => {
  const handleFAQChange = (e, index, field) => {
    const { value } = e.target;
    const faqs = [...config.faqs];
    faqs[index] = { ...faqs[index], [field]: value };
    setConfig({ ...config, faqs });
  };

  const handleAddFAQ = () => {
    const faqs = [...config.faqs, { title: "", content: "" }];
    setConfig({ ...config, faqs });
  };

  const handleDeleteFAQ = (index) => {
    const faqs = [...config.faqs];
    faqs.splice(index, 1);
    setConfig({ ...config, faqs });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const faqs = [...config.faqs];
    const [reorderedItem] = faqs.splice(result.source.index, 1);
    faqs.splice(result.destination.index, 0, reorderedItem);
    setConfig({ ...config, faqs });
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">FAQs</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" pb={3}>
            The FAQs section defines the FAQs that show up on the FAQ page. Add,
            delete, or reorder FAQs however you want them to display on the
            site.
          </Typography>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <Box {...provided.droppableProps} ref={provided.innerRef}>
                  {config.faqs.map((faq, index) => (
                    <Draggable
                      key={index}
                      draggableId={`${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          sx={{
                            mb: 2,
                            border: "1px solid grey",
                            borderRadius: "4px",
                            padding: "10px",
                          }}
                        >
                          <TextField
                            sx={{ mb: 1 }}
                            name="title"
                            label={`FAQ Title ${index + 1}`}
                            fullWidth
                            value={faq.title}
                            onChange={(e) => handleFAQChange(e, index, "title")}
                          />
                          <TextField
                            name="content"
                            label={`FAQ Content ${index + 1}`}
                            multiline
                            rows={4}
                            fullWidth
                            value={faq.content}
                            onChange={(e) =>
                              handleFAQChange(e, index, "content")
                            }
                          />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <Tooltip title="Drag to reorder">
                              <IconButton {...provided.dragHandleProps}>
                                <DragHandleIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete FAQ">
                              <IconButton
                                color="error"
                                onClick={() => handleDeleteFAQ(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
          <Button
            variant="outlined"
            onClick={handleAddFAQ}
            startIcon={<AddCircleIcon />}
            sx={{ mt: 2 }}
          >
            Add FAQ
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default FAQsAccordion;
