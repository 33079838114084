import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  TextField,
  Button,
  Grid,
  IconButton,
  Typography,
  InputAdornment,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const DiscountsAccordion = ({ config, setConfig, onValidationError }) => {
  const handleDiscountChange = (e, index, field) => {
    const { value } = e.target;
    const discounts = config.discounts ? [...config.discounts] : [];
    discounts[index] = {
      ...discounts[index],
      [field]: value,
    };
    setConfig({ ...config, discounts });

    onValidationError(!validatePrices(discounts));
  };

  const validatePrices = (discounts) => {
    for (let discount of discounts) {
      if (!isPositiveFloat(discount.price)) {
        return false;
      }
    }
    return true;
  };

  // Validate positive float
  const isPositiveFloat = (value) => {
    const floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
    if (!floatRegex.test(value)) return false;
    value = parseFloat(value);
    if (isNaN(value)) return false;
    return value >= 0;
  };

  const handleAddDiscount = () => {
    const newDiscount = {
      promoCode: "",
      price: 0,
    };
    const templateDiscount =
      config.discounts && config.discounts[0] ? config.discounts[0] : {};
    Object.keys(templateDiscount).forEach((key) => (newDiscount[key] = ""));
    const discounts = [...(config.discounts || []), newDiscount];
    setConfig({ ...config, discounts });
    onValidationError(!validatePrices(discounts));
  };

  const handleDeleteDiscount = (index) => {
    const discounts = config.discounts ? [...config.discounts] : [];
    discounts.splice(index, 1);
    setConfig({ ...config, discounts });
    onValidationError(!validatePrices(discounts));
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Discounts</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" pb={3}>
            The discounts section defines the promo codes available for users.
            These codes are NOT user specific, so they will work for anyone who
            has access to the code. The price denotes the price that user will
            pay when they redeem that promo code. Prices must be greater than or
            equal to $0.
          </Typography>
          {(config.discounts || []).map((discount, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Grid container spacing={2} alignItems="center">
                {Object.keys(discount).map((field, idx) => (
                  <Grid item xs={5} key={idx}>
                    <TextField
                      name={`${field}`}
                      label={`${
                        field.charAt(0).toUpperCase() + field.slice(1)
                      } ${index + 1}`}
                      fullWidth
                      value={discount[field]}
                      onChange={(e) => handleDiscountChange(e, index, field)}
                      error={
                        field === "price" && !isPositiveFloat(discount[field])
                      }
                      helperText={
                        field === "price" &&
                        !isPositiveFloat(discount[field]) &&
                        "Price must be greater than or equal to 0"
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {field === "price" ? "$" : ""}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                ))}
                <Grid item xs={2}>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteDiscount(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          ))}
          <Button
            variant="outlined"
            onClick={handleAddDiscount}
            startIcon={<AddCircleIcon />}
            sx={{ mt: 2 }}
          >
            Add Discount
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default DiscountsAccordion;
