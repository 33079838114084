import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ky from "ky";
import * as React from "react";
import { useEffect, useState } from "react";
import { parsePhoneNumber } from "awesome-phonenumber";

import StandardAlert from "../alerts/StandardAlert";
import { enableSwipe } from "../../utils/utils";

export default function EditClassDialog(props) {
  const {
    editCourseIsOpen,
    setEditCourseIsOpen,
    setIsLoading,
    setOuterAlertLevel,
    setOuterAlertMessage,
    setOuterAlertIsOpen,
    datastore,
  } = props;

  const [allBadges, setAllBadges] = useState([]);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertLevel, setAlertLevel] = useState("error");
  const [selectedBadge, setSelectedBadge] = useState({ courseId: "Select" });

  useEffect(() => {
    const fetchBadges = () => {
      return ky
        .get(
          window._env_.REACT_APP_API_URL +
            "/courses/" +
            datastore.config.configId,
          {
            timeout: window._env_.REACT_APP_TIMEOUT,
          },
        )
        .json();
    };
    setIsLoading(true);
    fetchBadges()
      .then((data) => {
        setAllBadges(data.flat());
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setOuterAlertLevel("error");
        setOuterAlertMessage("Error: Could not fetch classes.");
        setOuterAlertIsOpen(true);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "counselorPhoneNumber") {
      const pn = parsePhoneNumber(value, { regionCode: "US" });
      setSelectedBadge({
        ...selectedBadge,
        [name]: pn.number.national,
      });
    } else {
      setSelectedBadge({
        ...selectedBadge,
        [name]: value,
      });
    }
  };

  const handleBadgeSelect = (badge) => {
    if (badge === "Select") {
      setSelectedBadge({ courseId: "Select" });
    } else if (badge === null) {
      // Case where the user deletes the text, do nothing
    } else {
      // Ensure that all fields are not null, required for value prop in TextField
      Object.keys(badge).forEach((key) => {
        if (badge[key] === null) {
          badge[key] = "";
        }
      });
      setSelectedBadge(badge);
    }
  };

  return (
    <Dialog
      open={editCourseIsOpen}
      maxWidth="xs"
      onClose={() => {
        enableSwipe();
        setEditCourseIsOpen(false);
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h5" noWrap sx={{ flexGrow: 1 }}>
            <strong>Edit a Class</strong>
          </Typography>
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={() => {
                enableSwipe();
                setEditCourseIsOpen(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Select the class you want to update. Then modify the form below to
          make changes. Once you are done, click the update course button below.
          Be sure to keep all required fields populated!
        </Typography>
        <Box component="form" sx={{ mt: 1 }}>
          <Autocomplete
            options={[{ courseId: "Select" }, ...allBadges]}
            getOptionLabel={(option) => {
              if (option.courseId === "Select") return option.courseId;
              else {
                return `${option.meritBadgeName} - ID: ${option.courseId} - Sess: ${option.startingSession}`;
              }
            }}
            isOptionEqualToValue={(option, value) => {
              return option.courseId === value.courseId;
            }}
            value={selectedBadge}
            onChange={(event, newValue) => {
              handleBadgeSelect(newValue ? newValue : null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Class"
                margin="normal"
                fullWidth
                required
              />
            )}
          />
          {selectedBadge.courseId !== "Select" ? (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedBadge.isEagle}
                    onChange={(e) => {
                      setSelectedBadge({
                        ...selectedBadge,
                        isEagle: e.target.checked,
                      });
                    }}
                  />
                }
                label="Is Eagle Required?"
              />
              <TextField
                required
                margin="normal"
                fullWidth
                id="minAge-input"
                name="minAge"
                label="Minimum Age"
                type="number"
                value={selectedBadge.minAge}
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                fullWidth
                id="modSpots-input"
                name="modSpots"
                label="Add/Remove Spots (3 = add 3 spots, -2 = remove 2 spots)"
                type="number"
                value={selectedBadge.modSpots}
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                fullWidth
                id="counselorFirstName"
                label="Counselor's First Name"
                name="counselorFirstName"
                value={selectedBadge.counselorFirstName}
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                fullWidth
                id="counselorLastName"
                label="Counselor's Last Name"
                name="counselorLastName"
                value={selectedBadge.counselorLastName}
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                fullWidth
                id="counselorEmailAddress"
                label="Counselor's Email"
                name="counselorEmailAddress"
                value={selectedBadge.counselorEmailAddress}
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                fullWidth
                id="counselorPhoneNumber"
                label="Counselor's Phone Number"
                name="counselorPhoneNumber"
                value={selectedBadge.counselorPhoneNumber}
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                fullWidth
                id="prereqs"
                label="Prerequisites (Ex: 1a, 3e, 4)"
                name="prereqs"
                value={selectedBadge.prereqs}
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                fullWidth
                id="additionalCost"
                label="Additional Cost (Ex: 2.00)"
                name="additionalCost"
                type="number"
                value={selectedBadge.additionalCost}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              <Button
                onClick={() => {
                  setIsLoading(true);
                  const finalValues = {
                    courseId: parseInt(selectedBadge.courseId),
                    meritBadgeName: selectedBadge.meritBadgeName,
                    isEagle: selectedBadge.isEagle,
                    counselorFirstName: selectedBadge.counselorFirstName,
                    counselorLastName: selectedBadge.counselorLastName,
                    counselorPhoneNumber: selectedBadge.counselorPhoneNumber,
                    counselorEmailAddress: selectedBadge.counselorEmailAddress,
                    modSpots: selectedBadge.modSpots
                      ? parseInt(selectedBadge.modSpots)
                      : 0,
                    additionalCost: selectedBadge.additionalCost,
                    prereqs: selectedBadge.prereqs,
                    configId: datastore.config.configId,
                    minAge: parseInt(selectedBadge.minAge) || 10,
                  };
                  ky.patch(window._env_.REACT_APP_API_URL + "/editcourse", {
                    json: finalValues,
                    timeout: window._env_.REACT_APP_TIMEOUT,
                  })
                    .json()
                    .then(() => {
                      setIsLoading(false);
                      setOuterAlertLevel("success");
                      setOuterAlertMessage("Course Updated!");
                      setOuterAlertIsOpen(true);
                      setEditCourseIsOpen(false);
                      enableSwipe();
                    })
                    .catch((e) => {
                      console.error(e);
                      setAlertLevel("error");
                      setAlertMessage(
                        "Unable to update course. Please try again!",
                      );
                      setAlertIsOpen(true);
                      setIsLoading(false);
                    });
                }}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Update Course
              </Button>
            </>
          ) : null}
        </Box>
        <StandardAlert
          alertIsOpen={alertIsOpen}
          setAlertIsOpen={setAlertIsOpen}
          alertLevel={alertLevel}
          alertMessage={alertMessage}
          width="80%"
        />
      </DialogContent>
    </Dialog>
  );
}
