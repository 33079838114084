import React, { useEffect } from "react";
import { Box, Container, Grid, Typography, Link } from "@mui/material";
import ky from "ky";

export const Footer = ({ setFooterHeight }) => {
  const [apiVersion, setApiVersion] = React.useState("");
  const appVersion = window._env_.REACT_APP_VERSION;
  const footerRef = React.useRef(null);

  useEffect(() => {
    if (!footerRef?.current?.clientHeight) {
      return;
    }
    setFooterHeight(footerRef?.current?.clientHeight);
  }, [footerRef?.current?.clientHeight]);

  React.useEffect(() => {
    ky.get(window._env_.REACT_APP_API_URL + "/actuator/info", {
      timeout: window._env_.REACT_APP_TIMEOUT,
    })
      .json()
      .then((response) => {
        setApiVersion(response.app.version);
      })
      .catch((e) => {
        setApiVersion("unknown");
        console.error(e);
      });
  }, []);

  return (
    <Box
      ref={footerRef}
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: "primary.main",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography
              color="primary.contrastText"
              variant="subtitle1"
              align="center"
            >
              Copyright{" "}
              <Link
                href="https://badgereg.com/"
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
              >
                Badge Reg
              </Link>{" "}
              © {new Date().getFullYear()}
            </Typography>
            {appVersion && (
              <Typography
                color="primary.contrastText"
                variant="caption"
                align="center"
                sx={{ display: "grid" }}
              >
                UI: v{appVersion}{" "}
                {!["", "unknown"].includes(apiVersion) && "API: v" + apiVersion}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
