import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { HexColorInput } from "react-colorful";

import { PopoverPicker } from "../pickers/PopoverPicker";

const ColorPaletteAccordion = ({ config, setConfig, onValidationError }) => {
  const validateColors = (color) => {
    return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color);
  };

  const handleColorChange = (color, field) => {
    const realColor = typeof color === "string" ? color : "#" + color.hex;
    setConfig({
      ...config,
      colorPalette: {
        ...config.colorPalette,
        [field]: realColor,
      },
    });
    onValidationError(!validateColors(realColor));
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Color Palette</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" pb={3}>
            The color palette defines the colors for the website and the emails.
          </Typography>
          {Object.entries(config.colorPalette).map(([field, color]) => (
            <Box
              key={field}
              sx={{ display: "flex", alignItems: "center", mb: 2 }}
            >
              <Typography variant="subtitle1" sx={{ width: "100px" }}>
                {field.charAt(0).toUpperCase() + field.slice(1)}:
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <HexColorInput
                  color={color}
                  onChange={(color) => handleColorChange(color, field)}
                  style={{
                    display: "block",
                    boxSizing: "border-box",
                    width: "90px",
                    margin: "0px 20px",
                    padding: "6px",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    background: "#eee",
                    outline: "none",
                    font: "inherit",
                    textTransform: "uppercase",
                    textAlign: "center",
                  }}
                />
                <PopoverPicker
                  color={color}
                  onChange={(color) => handleColorChange(color, field)}
                />
              </Stack>
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ColorPaletteAccordion;
