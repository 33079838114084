import { Dialog } from "@mui/material";
import React from "react";

import Logo from "../animations/324Logo";

export default function LogoDialog(props) {
  const { handleClose, open } = props;
  return (
    <Dialog
      open={open}
      fullScreen={true}
      TransitionProps={{
        timeout: {
          appear: 0,
          enter: 0,
          exit: 1000,
        },
      }}
    >
      <Logo handleClose={handleClose} />
    </Dialog>
  );
}
