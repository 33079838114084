import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  TextField,
  Typography,
  Grid,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const convert12to24 = (time12h) => {
  const [time, modifier] = time12h.split(/(?=[ap]m)/);
  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "pm") {
    hours = parseInt(hours, 10) + 12;
  }

  // Pad hours with zero if it's only one digit.
  hours = hours.toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};

const convert24to12 = (time24h) => {
  let [hours, minutes] = time24h.split(":");

  // Remove padding zero from hours if it exists
  hours = parseInt(hours, 10);

  let modifier = "am";
  if (hours >= 12) {
    modifier = "pm";
    if (hours > 12) {
      hours -= 12;
    }
  } else if (hours === 0) {
    hours = 12;
  }

  return `${hours}:${minutes}${modifier}`;
};

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
};
const EventDetailsAccordion = ({ config, setConfig, onValidationError }) => {
  const [isDateError, setIsDateError] = React.useState(false);
  const [isCloseDateError, setIsCloseDateError] = React.useState(false);

  const handleInputChange = (e) => {
    setConfig({ ...config, [e.target.name]: e.target.value });
  };

  const handleTimeInputChange = (e) => {
    const value = convert24to12(e.target.value);
    handleInputChange({ target: { name: e.target.name, value } });
  };

  const validateRegistrationOpenDate = (registrationOpenDate, eventDate) => {
    const regOpenDate = dayjs(registrationOpenDate, "MMMM D, YYYY");
    const eventDt = dayjs(eventDate, "MMMM D, YYYY");
    return regOpenDate.isBefore(eventDt);
  };

  const handleRegistrationOpenDateChange = (val) => {
    const formattedDate = formatDate(val["$d"]);
    const isValid = validateRegistrationOpenDate(
      formattedDate,
      config.eventDate,
    );
    setIsDateError(!isValid);
    onValidationError(!isValid);
    handleInputChange({
      target: {
        name: "regOpenDate",
        value: formattedDate,
      },
    });
  };

  const validateRegistrationCloseDate = (registrationCloseDate, eventDate) => {
    const regCloseDate = dayjs(registrationCloseDate, "MMMM D, YYYY");
    const eventDt = dayjs(eventDate, "MMMM D, YYYY");
    return regCloseDate.isBefore(eventDt) || regCloseDate.isSame(eventDt);
  };

  const handleRegistrationCloseDateChange = (val) => {
    const formattedDate = formatDate(val["$d"]);
    const isValid = validateRegistrationCloseDate(
      formattedDate,
      config.eventDate,
    );
    setIsCloseDateError(!isValid);
    onValidationError(!isValid);
    handleInputChange({
      target: {
        name: "regCloseDate",
        value: formattedDate,
      },
    });
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Event Details</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" pb={3}>
            The event details section contains some of the most important
            information. Please make sure this info is accurate before you save
            it!
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ mb: 2 }}
                  name="eventDate"
                  label="Event Date"
                  fullWidth
                  onChange={(val) => {
                    handleInputChange({
                      target: {
                        name: "eventDate",
                        value: formatDate(val["$d"]),
                      },
                    });
                  }}
                  defaultValue={dayjs(config.eventDate)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <div>{/*  Empty space for now */}</div>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ mb: 2 }}
                  name="regOpenDate"
                  label="Registration Open Date"
                  fullWidth
                  onChange={handleRegistrationOpenDateChange}
                  value={dayjs(config.regOpenDate)}
                />
                {isDateError && (
                  <Typography variant="caption" color="error">
                    Registration open date must be before the event date
                  </Typography>
                )}
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ mb: 2 }}
                  name="regCloseDate"
                  label="Registration Close Date"
                  fullWidth
                  onChange={handleRegistrationCloseDateChange}
                  value={
                    config.regCloseDate
                      ? dayjs(config.regCloseDate)
                      : dayjs(config.eventDate)
                  }
                />
                {isCloseDateError && (
                  <Typography variant="caption" color="error">
                    Registration close date must be before or on the event date
                  </Typography>
                )}
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} mt={-3} mb={1}>
              <Typography variant="caption">
                Please note these dates are inclusive. This means that reg open
                will be at 12:00 AM on the date you select and reg close will be
                at 11:59 PM on the date you select.
              </Typography>
            </Grid>
            <Grid item xs={12} mt={0} mb={0}>
              <TextField
                sx={{ mb: 2 }}
                name="regBypassCode"
                label="Registration Bypass Code"
                fullWidth
                value={config.regBypassCode}
                onChange={handleInputChange}
                helperText="This code allows users to bypass the registration open and close dates. Leave blank to disable."
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ mb: 2 }}
                name="eventStartTime"
                label="Event Start Time"
                fullWidth
                type="time"
                value={convert12to24(config.eventStartTime)}
                onChange={handleTimeInputChange}
                inputProps={{
                  step: 1800, // 30 min
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ mb: 2 }}
                name="eventEndTime"
                label="Event End Time"
                fullWidth
                type="time"
                value={convert12to24(config.eventEndTime)}
                onChange={handleTimeInputChange}
                inputProps={{
                  step: 1800, // 30 min
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ mb: 2 }}
                name="eventPrice"
                label="Event Price"
                type="number"
                fullWidth
                value={config.eventPrice}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                helperText="The price regular users will be charged for your event. (See discounts for other prices)"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ mb: 2 }}
                name="paypalClientId"
                label="Paypal Client ID"
                fullWidth
                value={config.paypalClientId}
                onChange={handleInputChange}
                helperText="This is from your PayPal Developer Portal and is required to collect payment."
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ mb: 2 }}
                name="paypalClientSecret"
                label="Paypal Client Secret"
                fullWidth
                value={config.paypalClientSecret}
                onChange={handleInputChange}
                helperText="This is from your PayPal Developer Portal and is required to collect payment."
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ pl: 2 }}
                      checked={config.counselorInterestIsEnabled}
                      onChange={(e) =>
                        setConfig({
                          ...config,
                          counselorInterestIsEnabled: e.target.checked,
                        })
                      }
                      name="counselorInterestIsEnabled"
                    />
                  }
                  label="Enable Counselor Interest Form on Contact Page"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default EventDetailsAccordion;
