import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import ky from "ky";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import StandardAlert from "../components/alerts/StandardAlert";
import { isSmallOrMobileDevice } from "../utils/utils";

export default function Login(props) {
  const {
    datastore,
    headerOpen,
    setHeaderOpen,
    headerHeight,
    setHeaderHeight,
    footerHeight,
    setFooterHeight,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const [formUsername, setUsername] = React.useState("");
  const [formPassword, setPassword] = React.useState("");
  const [alertIsOpen, setAlertIsOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertLevel, setAlertLevel] = useState("success");
  const [showPassword, setShowPassword] = useState("password");

  useEffect(() => {
    if (
      location.state &&
      location.state.hasOwnProperty("accountJustCreated") &&
      location.state.accountJustCreated === true
    ) {
      setAlertMessage(
        "Your account was created successfully. Please login to continue...",
      );
      setAlertLevel("success");
      setAlertIsOpen(true);
    }
  }, []);

  const login = () => {
    ky.post(window._env_.REACT_APP_API_URL + "/login", {
      json: {
        username: formUsername,
        password: formPassword,
        configId: datastore.config.configId,
      },
      timeout: window._env_.REACT_APP_TIMEOUT,
    })
      .json()
      .then((resp) => {
        // TODO: Change the keys to be unique to config id...
        localStorage.setItem("mb_college_username", formUsername);
        localStorage.setItem("mb_college_session", "active");
        localStorage.setItem("mb_first_name", resp.firstName);
        localStorage.setItem("mb_last_name", resp.lastName);
        localStorage.setItem("mb_user_id", resp.userId);
        localStorage.setItem("mb_config_id", datastore.config.configId);
        localStorage.setItem("mb_login_timestamp", new Date().toString());

        if (resp.isOwner === true) {
          localStorage.setItem("mb_privilege_level", "owner");
        } else if (resp.isAdmin === true) {
          localStorage.setItem("mb_privilege_level", "admin");
        } else if (resp.isCounselor === true) {
          localStorage.setItem("mb_privilege_level", "counselor");
        } else {
          localStorage.setItem("mb_privilege_level", "user");
        }
        navigate("/");
      })
      .catch((e) => {
        console.log(e);
        if (e.name === "HTTPError") {
          if (e.response.status === 403) {
            setAlertMessage(
              "Incorrect Username or Password. Please try again...",
            );
          } else {
            setAlertMessage("Unable to login. Please try again...");
          }
        } else {
          setAlertMessage("Unable to login. Please try again...");
        }
        setAlertLevel("error");
        setAlertIsOpen(true);
      });
  };

  function handleSubmit() {
    login();
  }
  return (
    <>
      <Header
        datastore={datastore}
        headerOpen={headerOpen}
        setHeaderOpen={setHeaderOpen}
        headerHeight={headerHeight}
        setHeaderHeight={setHeaderHeight}
      />
      <Box
        sx={{
          flexDirection: "column",
          backgroundColor: "primary.light",
          minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
        }}
        mt={`${headerHeight}px`}
        pb={1}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            flexDirection: "column",
            backgroundColor: "primary.light",
          }}
        >
          <StandardAlert
            alertIsOpen={alertIsOpen}
            setAlertIsOpen={setAlertIsOpen}
            alertLevel={alertLevel}
            alertMessage={alertMessage}
          />
          <Box
            sx={{
              marginTop: 6,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{
                marginTop: isSmallOrMobileDevice() ? 30 : 20,
              }}
              width={isSmallOrMobileDevice() ? 100 : 200}
              src={
                datastore.config.logoFileId
                  ? window._env_.REACT_APP_API_URL +
                    "/getFile/" +
                    datastore.config.configId +
                    "/" +
                    datastore.config.logoFileId
                  : "./bsa.svg"
              }
              alt="logo"
            />
            <Typography component="h1" variant="h5" pt={2}>
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{
                mt: 1,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                fullWidth
                margin="normal"
                required
                id="username"
                label="Username or Email"
                name="username"
                autoComplete="username"
                value={formUsername || undefined}
                autoFocus
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              />
              <TextField
                fullWidth
                size="medium"
                margin="normal"
                required
                name="password"
                label="Password"
                type={showPassword}
                value={formPassword || undefined}
                id="password"
                autoComplete="current-password"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          if (showPassword === "password") {
                            setShowPassword("text");
                          } else {
                            setShowPassword("password");
                          }
                        }}
                      >
                        {showPassword === "text" ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                onClick={handleSubmit}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Stack justifyContent="center" alignItems="center">
                <Typography variant="body1">
                  <strong>Need an account?</strong>
                </Typography>
                <Typography>Click below to get started!</Typography>
              </Stack>
              <Button
                onClick={() => {
                  navigate("/signup");
                }}
                variant="contained"
                sx={{
                  mt: 1,
                  mb: 2,
                }}
              >
                Create Account
              </Button>
              <Stack justifyContent="center" alignItems="center" spacing={0.5}>
                <Typography variant="body1">
                  <strong>Need Help?</strong>
                </Typography>
                <Link to="/forgotPassword" variant="body2">
                  Forgot Password
                </Link>
                <Link to="/faq" variant="body2">
                  Frequently Asked Questions
                </Link>
                <Link to="/contact" variant="body2">
                  Contact Us
                </Link>
              </Stack>
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer footerHeight={footerHeight} setFooterHeight={setFooterHeight} />
    </>
  );
}
