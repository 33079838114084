import Grid2 from "@mui/material/Unstable_Grid2";
import { Box, Container, Stack, Typography, Link } from "@mui/material";
import { useEffect, useState } from "react";
import ky from "ky";
import addressFormatter from "@fragaria/address-formatter";

import Map from "../maps/Map";
import { isSmallOrMobileDevice } from "../../utils/utils";

export default function KeyInfoBanner(props) {
  const { datastore } = props;

  const [location, setLocation] = useState(null);

  const formatted = datastore.config.eventAddress
    ? addressFormatter.format({
        houseNumber: datastore.config.eventAddress.houseNumber,
        road: datastore.config.eventAddress.road,
        neighbourhood: datastore.config.eventAddress.neighbourhood,
        city: datastore.config.eventAddress.city,
        postcode: parseInt(datastore.config.eventAddress.postcode),
        county: datastore.config.eventAddress.county,
        state: datastore.config.eventAddress.state,
        country: datastore.config.eventAddress.country,
        countryCode: datastore.config.eventAddress.countryCode,
      })
    : "";

  useEffect(() => {
    const getAddressCoordinates = (address) => {
      const encodedAddress = encodeURIComponent(address);
      const apiUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${encodedAddress}`;
      return ky.get(apiUrl).json();
    };

    getAddressCoordinates(formatted)
      .then((data) => {
        if (data.length > 0) {
          const { lat, lon } = data[0];
          setLocation({ latitude: lat, longitude: lon });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    // TODO: maxWidth="98%" is not a good solution to horizontal scroll bar...
    <Box sx={{ flexGrow: 1 }} pt={2} pb={3} maxWidth="98%">
      <Grid2 container spacing={2} columns={12}>
        <Grid2 lg md sm xs>
          <Container
            sx={{ textAlign: isSmallOrMobileDevice() ? "left" : "left" }}
          >
            <Stack spacing={2}>
              <Typography variant="h4">
                <strong>Key Info:</strong>
              </Typography>
              {datastore.config.keyInfo &&
                datastore.config.keyInfo.map((info) => (
                  <Typography variant="h5" key={info}>
                    <strong>• </strong>
                    {info}
                  </Typography>
                ))}
              <Link
                href={
                  "https://www.google.com/maps/search/?api=1&query=" +
                  encodeURIComponent(formatted)
                }
                underline="hover"
                target="_blank"
                rel="noreferrer"
                color="inherit"
                sx={{ textAlign: "center" }}
              >
                <Typography variant="h5">
                  <strong>
                    {datastore.config.eventAddress &&
                      datastore.config.eventAddress.name}
                  </strong>
                </Typography>
                {formatted.split("\n").map((line) => (
                  <Typography variant="h6" key={line}>
                    {line}
                  </Typography>
                ))}
              </Link>
            </Stack>
          </Container>
        </Grid2>
        <Grid2
          md={6}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: { xs: 500, sm: 500, md: "fill" },
          }}
        >
          <Container sx={{ height: "100%" }}>
            {location && <Map location={location} address={formatted} />}
          </Container>
        </Grid2>
      </Grid2>
    </Box>
  );
}
