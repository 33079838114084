import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";

import { enableSwipe } from "../../utils/utils";

export default function AcknowledgeFeeDialog(props) {
  const { acknowledgeFeeDialogIsOpen, setAcknowledgeFeeDialogIsOpen, session } =
    props;

  return (
    <Dialog
      open={acknowledgeFeeDialogIsOpen}
      maxWidth="xs"
      onClose={() => {
        enableSwipe();
        setAcknowledgeFeeDialogIsOpen(false);
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h5" noWrap sx={{ flexGrow: 1 }}>
            <strong>Class Fee Acknowledgment</strong>
          </Typography>
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={() => {
                enableSwipe();
                setAcknowledgeFeeDialogIsOpen(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          This class has an additional cost of{" "}
          <strong>${session.additionalCost}</strong>. Please be prepared to
          bring this amount in cash on the day of the event. This money is used
          to reimburse the counselor for supplies needed for the{" "}
          {session.meritBadgeName} class.
        </Typography>
        <Typography variant="body1">
          <strong>
            Failure to do so may result in your scout not being able to
            participate in the class.
          </strong>
        </Typography>
        <Stack direction="row" justifyContent="space-evenly" spacing={2} mt={2}>
          <Button
            variant="contained"
            onClick={() => {
              setAcknowledgeFeeDialogIsOpen(false);
            }}
          >
            Accept
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
