import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import {
  calculateRegistrationStatus,
  capitalize,
  disableSwipe,
} from "../../utils/utils";
import EditScoutDialog from "../dialogs/EditScoutDialog";
import RegistrationInfoDialog from "../dialogs/RegistrationInfoDialog";
import RetryPaymentDialog from "../dialogs/RetryPaymentDialog";

export default function ScoutCard(props) {
  const navigate = useNavigate();
  const {
    scout,
    setIsLoading,
    setTriggerRefresh,
    setOuterAlertLevel,
    setOuterAlertMessage,
    setOuterAlertIsOpen,
    datastore,
  } = props;
  const [editScoutIsOpen, setEditScoutIsOpen] = useState(false);
  const [regInfoIsOpen, setRegInfoIsOpen] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [pathClicked, setPathClicked] = useState("");

  return (
    <Card sx={{ minWidth: 275, height: "100%" }}>
      <CardContent>
        <Typography variant="h5" component="div">
          <strong>
            {capitalize(scout.firstName)} {capitalize(scout.lastName)}
          </strong>
        </Typography>
        <Typography color="text.secondary">
          {capitalize(scout.unitType)} {scout.unitNumber}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {capitalize(scout.gender)}, {scout.birthdate}
        </Typography>
        <Typography variant="h6" component="div">
          <strong>Payment:</strong>
        </Typography>
        {scout.initialRegistrationTime ? (
          scout.captureId ? (
            <>
              <Typography variant="body2" component="div">
                <strong>Amount Paid: </strong>${scout.paymentAmount}
              </Typography>
              <Typography variant="body2" component="div">
                <strong>Payment Method: </strong>
                {scout.paymentMethod}
              </Typography>
              <Typography variant="body2" component="div">
                <strong>Order ID: </strong>
                {scout.orderId}
              </Typography>
              <Typography variant="body2" component="div" sx={{ mb: 1.5 }}>
                <strong>Registration Time: </strong>
                {new Date(scout.initialRegistrationTime).toLocaleString(
                  "en-US",
                  {
                    timeZone: "America/Los_Angeles",
                  },
                )}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="body2" component="div" color="error">
                <strong>PAYMENT FAILED!</strong>
              </Typography>
              <Typography variant="body2" component="div" color="error">
                You have 72 hours to complete payment or your registration will
                be voided.
              </Typography>
              <Button
                variant="outlined"
                size="small"
                color="error"
                onClick={() => {
                  setIsPaymentOpen(true);
                }}
              >
                Retry Payment
              </Button>
            </>
          )
        ) : (
          <Typography variant="body2" component="div">
            None
          </Typography>
        )}
        {scout.food ? (
          <>
            <Typography variant="h6" component="div">
              <strong>Food:</strong>
            </Typography>
            <Typography variant="body2" component="div">
              {scout.food}
            </Typography>
          </>
        ) : null}
        <Typography variant="h6" component="div">
          <strong>Schedule:</strong>
        </Typography>
        {scout.initialRegistrationTime !== null ? (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    borderLeft: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  Session 1:
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {scout.session1BadgeName
                    ? scout.session1BadgeName
                    : "No class Selected"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    borderLeft: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  Session 2:
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {scout.session2BadgeName
                    ? scout.session2BadgeName
                    : "No class Selected"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    borderLeft: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  Session 3:
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {scout.session3BadgeName
                    ? scout.session3BadgeName
                    : "No class Selected"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    borderLeft: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  Session 4:
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {scout.session4BadgeName
                    ? scout.session4BadgeName
                    : "No class Selected"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <Typography>
            It doesnt look like {capitalize(scout.firstName)} has signed up for
            classes yet.
          </Typography>
        )}

        <Typography variant="body2" sx={{ mt: 2.5 }}>
          {scout.initialRegistrationTime !== null
            ? null
            : "Click the Register button below to enroll in classes!"}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <Box sx={{ flexGrow: 1, mb: 2 }}>
          <Grid2 container spacing={2}>
            <Grid2
              xs={12}
              sm={6}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                size="small"
                onClick={() => {
                  disableSwipe();
                  setEditScoutIsOpen(true);
                }}
                color="primary"
                variant="outlined"
              >
                Edit Scout
              </Button>
            </Grid2>
            <Grid2
              xs={12}
              sm={6}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {scout.initialRegistrationTime !== null ? (
                <Tooltip title={"Modify your scout's classes."}>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    disabled={false}
                    onClick={() => {
                      const isRegOpen = calculateRegistrationStatus(
                        datastore.config.regOpenDate,
                        datastore.config.regCloseDate,
                        datastore.config.eventDate,
                      );
                      if (isRegOpen === "open") {
                        sessionStorage.setItem(
                          "mb_college_scout",
                          JSON.stringify(scout),
                        );
                        navigate("/edit-registration");
                      } else {
                        disableSwipe();
                        setPathClicked("/edit-registration");
                        setRegInfoIsOpen(true);
                      }
                    }}
                  >
                    Edit Registration
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    const isRegOpen = calculateRegistrationStatus(
                      datastore.config.regOpenDate,
                      datastore.config.regCloseDate,
                      datastore.config.eventDate,
                    );
                    console.log(isRegOpen);
                    if (isRegOpen === "open") {
                      sessionStorage.setItem(
                        "mb_college_scout",
                        JSON.stringify(scout),
                      );
                      navigate("/register");
                    } else {
                      disableSwipe();
                      setPathClicked("/register");
                      setRegInfoIsOpen(true);
                    }
                  }}
                >
                  Register{" "}
                </Button>
              )}
            </Grid2>
          </Grid2>
        </Box>
      </CardActions>
      <EditScoutDialog
        editScoutIsOpen={editScoutIsOpen}
        setEditScoutIsOpen={setEditScoutIsOpen}
        scout={scout}
        setIsLoading={setIsLoading}
        setTriggerRefresh={setTriggerRefresh}
        setOuterAlertLevel={setOuterAlertLevel}
        setOuterAlertMessage={setOuterAlertMessage}
        setOuterAlertIsOpen={setOuterAlertIsOpen}
        datastore={datastore}
      />
      <RegistrationInfoDialog
        registrationStatus={calculateRegistrationStatus(
          datastore.config.regOpenDate,
          datastore.config.regCloseDate,
          datastore.config.eventDate,
        )}
        scout={scout}
        openState={regInfoIsOpen}
        closeState={() => {
          disableSwipe();
          setRegInfoIsOpen(false);
        }}
        datastore={datastore}
        pathClicked={pathClicked}
      />
      <PayPalScriptProvider
        options={{
          "client-id": datastore.config.paypalClientId,
        }}
      >
        {isPaymentOpen ? (
          <RetryPaymentDialog
            isOpen={isPaymentOpen}
            setIsOpen={setIsPaymentOpen}
            scout={scout}
            datastore={datastore}
          />
        ) : null}
      </PayPalScriptProvider>
    </Card>
  );
}
