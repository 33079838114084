import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import InfoIcon from "@mui/icons-material/Info";
import { sort } from "fast-sort";

import {
  calculateAgeOnDate,
  disableSwipe,
  rangeArray,
} from "../../utils/utils";
import BadgeInfoDialog from "../dialogs/BadgeInfoDialog";
import AcknowledgeFeeDialog from "../dialogs/AcknowledgeFeeDialog";

export default function BadgeRegisterCard(props) {
  const { badge, selectedBadges, setSelectedBadges, datastore } = props;

  const [expanded, setExpanded] = useState(false);
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState("");
  const [selectedSession, setSelectedSession] = useState({});
  const [feeDialogIsOpen, setFeeDialogIsOpen] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Paper key={badge.courseId} sx={{ marginBottom: 2 }}>
        <Grid2 container spacing={2} columns={12} pb={2} pl={2}>
          <Grid2
            lg={3}
            md={6}
            sm={6}
            xs={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <img
                src={badge[0].imageUrl}
                alt={badge[0].meritBadgeName}
                width={50}
                height={50}
              />
              <Typography>{badge[0].meritBadgeName}</Typography>
            </Stack>
          </Grid2>

          <Grid2
            lg={3}
            md={6}
            sm={6}
            xs={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Stack textAlign="center">
              <Typography noWrap>
                <strong>Spots Left:</strong>
              </Typography>
              <Typography noWrap variant="body2">
                {badge.reduce((n, { spotsRemaining }) => n + spotsRemaining, 0)}
              </Typography>
            </Stack>
          </Grid2>
          <Grid2
            lg={3}
            md={6}
            sm={6}
            xs={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Stack textAlign="center">
              <Typography noWrap>
                <strong>Info &</strong>
              </Typography>
              <Typography noWrap>
                <strong>Prereqs:</strong>
              </Typography>
              <div>
                <IconButton
                  size="small"
                  sx={{ boxShadow: 3 }}
                  onClick={() => {
                    disableSwipe();
                    setIsInfoDialogOpen(true);
                    setSelectedBadge(badge);
                  }}
                >
                  <InfoIcon fontSize="small" />
                </IconButton>
              </div>
            </Stack>
          </Grid2>
          <Grid2
            lg={3}
            md={6}
            sm={6}
            xs={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Stack textAlign="center">
              <Typography noWrap>
                <strong>Sessions:</strong>
              </Typography>
              <div>
                <IconButton
                  onClick={handleExpandClick}
                  sx={{ boxShadow: 3 }}
                  size="small"
                >
                  {expanded ? (
                    <ExpandLessIcon fontSize="small" />
                  ) : (
                    <ExpandMoreIcon fontSize="small" />
                  )}
                </IconButton>
              </div>
            </Stack>
          </Grid2>
        </Grid2>
        <Collapse in={expanded} timeout="auto">
          <Divider />
          <Box sx={{ width: "100%" }}>
            {sort(badge)
              .asc("startingSession")
              .map((sess, i, row) => {
                const isAgeRestricted =
                  calculateAgeOnDate(
                    JSON.parse(sessionStorage.getItem("mb_college_scout"))
                      .birthdate,
                    datastore.config.eventDate,
                  ) < sess.minAge;

                return (
                  <Box
                    key={sess.courseId}
                    sx={{
                      my: 2,
                      px: 2,
                      py: 1,
                    }}
                  >
                    <Grid2 container spacing={2} alignItems="center">
                      <Grid2 xs={6} sm={6} md={3}>
                        <Stack sx={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="body2" noWrap align="center">
                            <strong>Session {sess.startingSession}:</strong>
                          </Typography>
                          <Checkbox
                            checked={selectedBadges.some(
                              (course) => course.courseId === sess.courseId,
                            )}
                            disabled={
                              sess.spotsRemaining <= 0 ||
                              selectedBadges.some(
                                (course) =>
                                  course.meritBadgeName ===
                                    sess.meritBadgeName &&
                                  course.courseId !== sess.courseId,
                              ) ||
                              (!selectedBadges.some(
                                (course) => course.courseId === sess.courseId,
                              ) &&
                                selectedBadges.some((selectedBadge) => {
                                  const currentRange = rangeArray(
                                    sess.startingSession,
                                    sess.startingSession +
                                      sess.classDurationInSessions -
                                      1,
                                  );
                                  const selectedRange = rangeArray(
                                    selectedBadge.startingSession,
                                    selectedBadge.endingSession,
                                  );
                                  return currentRange.some((session) =>
                                    selectedRange.includes(session),
                                  );
                                })) ||
                              isAgeRestricted // Disable checkbox if age restricted
                            }
                            onChange={(e) => {
                              if (
                                sess.additionalCost &&
                                sess.additionalCost > 0 &&
                                e.target.checked
                              ) {
                                setSelectedSession(sess);
                                setFeeDialogIsOpen(true);
                              }
                              const selectedBadge = {
                                meritBadgeName: sess.meritBadgeName,
                                courseId: sess.courseId,
                                startingSession: sess.startingSession,
                                endingSession:
                                  sess.startingSession +
                                  sess.classDurationInSessions -
                                  1,
                                dur: sess.classDurationInSessions,
                              };
                              if (e.target.checked === true) {
                                setSelectedBadges((oldArray) => [
                                  ...oldArray,
                                  selectedBadge,
                                ]);
                              } else {
                                setSelectedBadges((oldArray) => {
                                  return oldArray.filter(
                                    (el) => el.courseId !== sess.courseId,
                                  );
                                });
                              }
                            }}
                          />
                        </Stack>
                      </Grid2>
                      <Grid2 xs={6} sm={6} md={3}>
                        <Stack
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body2" align="center">
                            <strong>Time:</strong>
                          </Typography>
                          <Typography variant="body2" noWrap align="center">
                            {sess.classTimes}
                          </Typography>
                        </Stack>
                      </Grid2>
                      <Grid2 xs={6} sm={6} md={3}>
                        <Stack
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body2" align="center">
                            <strong>Spots Left:</strong>
                          </Typography>
                          <Typography variant="body2" align="center">
                            {sess.spotsRemaining}
                          </Typography>
                        </Stack>
                      </Grid2>
                      <Grid2 xs={6} sm={6} md={3}>
                        <Stack
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body2" align="center">
                            <strong>Min Age:</strong>
                          </Typography>
                          <Typography
                            variant="body2"
                            color={isAgeRestricted ? "error" : "text.primary"}
                            align="center"
                          >
                            {sess.minAge}
                          </Typography>
                        </Stack>
                      </Grid2>
                    </Grid2>
                    {i !== row.length - 1 && (
                      <Divider sx={{ paddingTop: "15px" }} />
                    )}
                  </Box>
                );
              })}
          </Box>
        </Collapse>
      </Paper>
      <BadgeInfoDialog
        setSelectedBadge={setSelectedBadge}
        selectedBadge={selectedBadge}
        setIsInfoDialogOpen={setIsInfoDialogOpen}
        isInfoDialogOpen={isInfoDialogOpen}
        datastore={datastore}
      />
      <AcknowledgeFeeDialog
        acknowledgeFeeDialogIsOpen={feeDialogIsOpen}
        setAcknowledgeFeeDialogIsOpen={setFeeDialogIsOpen}
        session={selectedSession}
      />
    </>
  );
}
