import * as React from "react";
import { Backdrop, Box, CircularProgress, Divider } from "@mui/material";
import { useEffect } from "react";

import HomeBanner from "../components/banners/HomeBanner";
import Footer from "../components/footer/Footer";
import KeyInfoBanner from "../components/banners/KeyInfoBanner";
import MeritBadgeBanner from "../components/banners/MeritBadgeBanner";
import Header from "../components/header/Header";
import LogoDialog from "../components/dialogs/LogoDialog";
import { disableSwipe } from "../utils/utils";
import AudioDialog from "../components/dialogs/AudioDialog";

export default function Home(props) {
  const {
    datastore,
    setHeaderOpen,
    headerOpen,
    headerHeight,
    setHeaderHeight,
    footerHeight,
    setFooterHeight,
  } = props;
  const [animationOpen, setAnimationOpen] = React.useState(false);
  const [audioDialogOpen, setAudioDialogOpen] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(true);

  const handleClose = () => {
    setAnimationOpen(false);
  };

  // This use effect runs first, check if animation needs to run
  useEffect(() => {
    if (sessionStorage.getItem("previouslyAnimated") !== "true") {
      disableSwipe();
      setAudioDialogOpen(true);
    }
  }, []);

  return (
    <>
      <Header
        datastore={datastore}
        headerOpen={headerOpen}
        setHeaderOpen={setHeaderOpen}
        headerHeight={headerHeight}
        setHeaderHeight={setHeaderHeight}
      />
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: "primary.light",
          minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
        }}
        mt={`${headerHeight}px`}
        pb={1}
      >
        <HomeBanner datastore={datastore} />
        <KeyInfoBanner datastore={datastore} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Divider sx={{ width: "95%" }} />
        </div>
        <MeritBadgeBanner
          datastore={datastore}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </Box>
      <Footer footerHeight={footerHeight} setFooterHeight={setFooterHeight} />
      <Backdrop
        sx={{ color: "#fff", zIndex: "150000 !important" }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/*Only enable sounds on Crew 324 site*/}
      {["democollege.crew324.com", "mbcollege.crew324.com"].includes(
        datastore.config.eventDomain,
      ) ? (
        <>
          <LogoDialog handleClose={handleClose} open={animationOpen} />
          <AudioDialog
            datastore={datastore}
            open={audioDialogOpen}
            setOpen={setAudioDialogOpen}
            setAnimationOpen={setAnimationOpen}
          />
        </>
      ) : null}
    </>
  );
}
