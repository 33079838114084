import { Box, Container, Stack, Typography } from "@mui/material";
import * as React from "react";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import CounselorTable from "../components/table/CounselorTable";
import RemainingSpotsBarChart from "../components/charts/RemainingSpotsBarChart";

export default function Contact(props) {
  const {
    datastore,
    setHeaderOpen,
    headerOpen,
    headerHeight,
    setHeaderHeight,
    footerHeight,
    setFooterHeight,
  } = props;

  const [courseJson, setCourseJson] = React.useState(null);

  React.useEffect(() => {
    const url =
      window._env_.REACT_APP_API_URL + "/courses/" + datastore.config.configId;
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setCourseJson(json.flat());
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Header
        datastore={datastore}
        headerOpen={headerOpen}
        setHeaderOpen={setHeaderOpen}
        headerHeight={headerHeight}
        setHeaderHeight={setHeaderHeight}
      />
      <Box
        sx={{
          flexDirection: "column",
          backgroundColor: "primary.light",
          minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
        }}
        mt={`${headerHeight}px`}
        pb={1}
      >
        <Container>
          <Stack
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ paddingTop: 2 }}
          >
            <Typography variant="h4">
              <strong>Class Analytics</strong>
            </Typography>
            <RemainingSpotsBarChart data={courseJson} />
            <CounselorTable datastore={datastore} courseJson={courseJson} />
          </Stack>
        </Container>
      </Box>
      <Footer footerHeight={footerHeight} setFooterHeight={setFooterHeight} />
    </>
  );
}
