import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ky from "ky";
import * as React from "react";
import { useEffect, useState } from "react";

import StandardAlert from "../alerts/StandardAlert";
import { enableSwipe } from "../../utils/utils";

export default function AddCodeDialog(props) {
  const {
    open,
    setIsOpen,
    setIsLoading,
    setOuterAlertLevel,
    setOuterAlertMessage,
    setOuterAlertIsOpen,
    datastore,
  } = props;

  const [allBadges, setAllBadges] = useState([]);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertLevel, setAlertLevel] = useState("error");
  const [selectedBadge, setSelectedBadge] = useState({ courseId: "Select" });
  const [newAddCode, setNewAddCode] = useState();

  useEffect(() => {
    const fetchBadges = () => {
      return ky
        .get(
          window._env_.REACT_APP_API_URL +
            "/courses/" +
            datastore.config.configId,
          {
            timeout: window._env_.REACT_APP_TIMEOUT,
          },
        )
        .json();
    };
    setIsLoading(true);
    fetchBadges()
      .then((data) => {
        setAllBadges(data.flat());
        if (selectedBadge && selectedBadge.courseId !== "Select") {
          const badge = allBadges
            .flat()
            .find(
              (badge) => badge.courseId === parseInt(selectedBadge.courseId),
            );
          handleBadgeSelect(badge);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setOuterAlertLevel("error");
        setOuterAlertMessage("Error: Could not fetch classes.");
        setOuterAlertIsOpen(true);
      });
  }, [alertMessage]);

  const handleBadgeSelect = (badge) => {
    // console.log(badge);
    if (badge === "Select") {
      setSelectedBadge({ courseId: "Select" });
    } else if (badge === null) {
      // Case where the user deletes the text, do nothing
    } else {
      setSelectedBadge(badge);
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={() => {
        enableSwipe();
        setIsOpen(false);
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h5" noWrap sx={{ flexGrow: 1 }}>
            <strong>Add Codes</strong>
          </Typography>
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={() => {
                enableSwipe();
                setIsOpen(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Select the class you want to administer. A list will show you if the
          selected class has any codes. You are also able able to generate new
          add codes. Used add codes will be removed from the list.
        </Typography>
        <Typography variant="body1" mt={1}>
          <strong>Remember</strong> each add code should only be given out to 1
          scout as they are single use. Add codes will add 1 additional seat to
          the class at time of redemption, even if the class is full.
        </Typography>
        <Box component="form" sx={{ mt: 1 }}>
          <Autocomplete
            options={[{ courseId: "Select" }, ...allBadges]}
            getOptionLabel={(option) => {
              if (option.courseId === "Select") return option.courseId;
              else {
                return `${option.meritBadgeName} - ID: ${option.courseId} - Sess: ${option.startingSession}`;
              }
            }}
            isOptionEqualToValue={(option, value) => {
              // console.log(option, value);
              return option.courseId === value.courseId;
            }}
            value={selectedBadge}
            onChange={(event, newValue) => {
              handleBadgeSelect(newValue ? newValue : null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Class"
                margin="normal"
                fullWidth
                required
              />
            )}
          />
          {selectedBadge.courseId !== "Select" ? (
            <>
              {selectedBadge.addCodes.length > 0 || newAddCode ? (
                <>
                  {selectedBadge.addCodes.length === 1 &&
                  selectedBadge.addCodes[0] === newAddCode ? (
                    <></>
                  ) : (
                    <>
                      <Typography variant="body1" mb={1}>
                        <strong>Current Add Codes:</strong>
                      </Typography>
                      <Typography variant="body2" mb={2}>
                        The add codes shown below have probably been sent to
                        scouts already. If you need to generate a new add code,
                        click the button below.
                      </Typography>
                    </>
                  )}
                  {selectedBadge.addCodes.map((code) => {
                    if (code !== newAddCode) {
                      return (
                        <Typography variant="body1" key={code}>
                          • {code}
                        </Typography>
                      );
                    } else {
                      return null;
                    }
                  })}
                  {newAddCode ? (
                    <>
                      <Typography variant="body1" mt={2}>
                        <strong>New Add Code:</strong>
                      </Typography>
                      <Typography variant="body1">• {newAddCode}</Typography>
                      <Typography variant="body2" mt={1}>
                        Copy this code and send it to only 1 scout so they can
                        register in the session {selectedBadge.startingSession}{" "}
                        {selectedBadge.meritBadgeName} class.
                      </Typography>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <Typography variant="body1">
                    <strong>No Add Codes</strong>
                  </Typography>
                  <Typography variant="body1">
                    This course currently has no add codes. Click the button
                    below to generate one.
                  </Typography>
                </>
              )}
              <Button
                onClick={() => {
                  setIsLoading(true);
                  const finalValues = {
                    courseId: parseInt(selectedBadge.courseId),
                    configId: datastore.config.configId,
                  };
                  ky.put(window._env_.REACT_APP_API_URL + "/generateAddCode", {
                    json: finalValues,
                    timeout: window._env_.REACT_APP_TIMEOUT,
                  })
                    .json()
                    .then((data) => {
                      setNewAddCode(data);
                      setSelectedBadge((prevBadge) => {
                        return {
                          ...prevBadge,
                          addCodes: [...prevBadge.addCodes, data],
                        };
                      });
                      setIsLoading(false);
                      setOuterAlertLevel("success");
                      setOuterAlertMessage("Add Code Created!");
                      setOuterAlertIsOpen(true);
                      enableSwipe();
                    })
                    .catch((e) => {
                      console.error(e);
                      setAlertLevel("error");
                      setAlertMessage(
                        "Unable to generate add code. Please try again!",
                      );
                      setAlertIsOpen(true);
                      setIsLoading(false);
                    });
                }}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Create Add Code
              </Button>
            </>
          ) : null}
        </Box>
        <StandardAlert
          alertIsOpen={alertIsOpen}
          setAlertIsOpen={setAlertIsOpen}
          alertLevel={alertLevel}
          alertMessage={alertMessage}
          width="80%"
        />
      </DialogContent>
    </Dialog>
  );
}
