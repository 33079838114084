import {
  Box,
  Container,
  Paper,
  Typography,
  Button,
  Stack,
  CircularProgress,
  Backdrop,
  Grid,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { isOwner, disableSwipe, isAdmin, isCounselor } from "../utils/utils";
import AddClassDialog from "../components/dialogs/AddClassDialog";
import StandardAlert from "../components/alerts/StandardAlert";
import EditClassDialog from "../components/dialogs/EditClassDialog";
import EditScoutsClassesDialog from "../components/dialogs/EditScoutsClassesDialog";
import EditConfigDialog from "../components/dialogs/EditConfigDialog";
import ModifyAdminDialog from "../components/dialogs/ModifyAdminDialog";
import RemoveClassDialog from "../components/dialogs/RemoveClassDialog";
import VoidRegistrationDialog from "../components/dialogs/VoidRegistrationDialog";
import AddCodeDialog from "../components/dialogs/AddCodeDialog";

export default function Admin(props) {
  const {
    datastore,
    setHeaderOpen,
    headerOpen,
    headerHeight,
    setHeaderHeight,
    footerHeight,
    setFooterHeight,
  } = props;
  const navigate = useNavigate();

  const [addCourseIsOpen, setAddCourseIsOpen] = useState(false);
  const [editCourseIsOpen, setEditCourseIsOpen] = useState(false);
  const [removeCourseIsOpen, setRemoveCourseIsOpen] = useState(false);
  const [editConfigIsOpen, setEditConfigIsOpen] = useState(false);
  const [editScoutsClassesIsOpen, setEditScoutsClassesIsOpen] = useState(false);
  const [modifyAdminIsOpen, setModifyAdminIsOpen] = useState(false);
  const [voidRegistrationIsOpen, setVoidRegistrationIsOpen] = useState(false);
  const [generateAddCodeIsOpen, setGenerateAddCodeIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertLevel, setAlertLevel] = useState("success");
  return (
    <>
      <Header
        datastore={datastore}
        headerOpen={headerOpen}
        setHeaderOpen={setHeaderOpen}
        headerHeight={headerHeight}
        setHeaderHeight={setHeaderHeight}
      />
      <Box
        sx={{
          flexDirection: "column",
          backgroundColor: "primary.light",
          minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
        }}
        mt={`${headerHeight}px`}
        pb={1}
      >
        <Container>
          <Typography align="center" variant="h5" pt={2} pb={1}>
            <strong>Admin Page</strong>
          </Typography>
        </Container>
        <Stack spacing={2} alignItems="center" justifyContent="center">
          {isOwner() || isAdmin() || isCounselor() ? (
            <Container>
              <Paper>
                <Container>
                  <Typography align="center" variant="h5" pt={2}>
                    Event Analytics
                  </Typography>
                  <Typography align="center" variant="body1" pt={2}>
                    Use this section to see who is signed up for what and other
                    useful info.
                  </Typography>
                  <Grid container spacing={2} align="center" pb={2} pt={2}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Button
                        disabled={false}
                        onClick={() => {
                          navigate("/participants");
                        }}
                        color="primary"
                        variant="contained"
                      >
                        Participant Table
                      </Button>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Button
                        disabled={false}
                        onClick={() => {
                          navigate("/counselors");
                        }}
                        color="primary"
                        variant="contained"
                      >
                        Classes & Counselors Table
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </Paper>
            </Container>
          ) : null}
          {isOwner() || isAdmin() ? (
            <Container>
              <Paper>
                <Container>
                  <Typography align="center" variant="h5" pt={2}>
                    Modify Available Classes
                  </Typography>
                  <Typography align="center" variant="body1" pt={2}>
                    Use this section to Add, Edit, or Remove classes and manage
                    Add Codes.
                  </Typography>
                  <Grid container spacing={2} align="center" pb={2} pt={2}>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                      <Button
                        onClick={() => {
                          disableSwipe();
                          setAddCourseIsOpen(true);
                        }}
                        color="primary"
                        variant="contained"
                      >
                        Add Class
                      </Button>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                      <Button
                        onClick={() => {
                          disableSwipe();
                          setEditCourseIsOpen(true);
                        }}
                        color="primary"
                        variant="contained"
                      >
                        Edit Class
                      </Button>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                      <Button
                        onClick={() => {
                          disableSwipe();
                          setRemoveCourseIsOpen(true);
                        }}
                        color="primary"
                        variant="contained"
                      >
                        Remove Class
                      </Button>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                      <Button
                        onClick={() => {
                          disableSwipe();
                          setGenerateAddCodeIsOpen(true);
                        }}
                        color="primary"
                        variant="contained"
                      >
                        Add Codes
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </Paper>
            </Container>
          ) : null}
          {isOwner() || isAdmin() ? (
            <Container>
              <Paper>
                <Container>
                  <Typography align="center" variant="h5" pt={2}>
                    Modify Scout's Selections
                  </Typography>
                  <Typography align="center" variant="body1" pt={2}>
                    Use this section to change a scout's class selections.
                  </Typography>
                  <Grid container spacing={2} align="center" pb={2} pt={2}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <Button
                        onClick={() => {
                          disableSwipe();
                          setEditScoutsClassesIsOpen(true);
                        }}
                        color="primary"
                        variant="contained"
                      >
                        Modify Scout's Classes
                      </Button>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <Button
                        onClick={() => {
                          disableSwipe();
                          setVoidRegistrationIsOpen(true);
                        }}
                        color="primary"
                        variant="contained"
                      >
                        Void or Refund Scout
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </Paper>
            </Container>
          ) : null}
          {isOwner() ? (
            <Container>
              <Paper>
                <Container>
                  <Typography align="center" variant="h5" pt={2}>
                    Owner Settings
                  </Typography>
                  <Typography align="center" variant="body1" pt={2}>
                    These settings are only available to website owners. Use
                    caution when modifying these settings.
                  </Typography>
                  <Grid container spacing={2} align="center" pb={2} pt={2}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          disableSwipe();
                          setModifyAdminIsOpen(true);
                        }}
                      >
                        Modify User Privileges
                      </Button>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          disableSwipe();
                          setEditConfigIsOpen(true);
                        }}
                      >
                        Configure Site
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </Paper>
            </Container>
          ) : null}
        </Stack>
      </Box>
      <Footer footerHeight={footerHeight} setFooterHeight={setFooterHeight} />
      {addCourseIsOpen ? (
        <AddClassDialog
          addCourseIsOpen={addCourseIsOpen}
          setAddCourseIsOpen={setAddCourseIsOpen}
          setIsLoading={setIsLoading}
          setOuterAlertLevel={setAlertLevel}
          setOuterAlertMessage={setAlertMessage}
          setOuterAlertIsOpen={setAlertIsOpen}
          datastore={datastore}
        />
      ) : null}
      {editCourseIsOpen ? (
        <EditClassDialog
          editCourseIsOpen={editCourseIsOpen}
          setEditCourseIsOpen={setEditCourseIsOpen}
          setIsLoading={setIsLoading}
          setOuterAlertLevel={setAlertLevel}
          setOuterAlertMessage={setAlertMessage}
          setOuterAlertIsOpen={setAlertIsOpen}
          datastore={datastore}
        />
      ) : null}
      {removeCourseIsOpen ? (
        <RemoveClassDialog
          removeCourseIsOpen={removeCourseIsOpen}
          setRemoveCourseIsOpen={setRemoveCourseIsOpen}
          setIsLoading={setIsLoading}
          setOuterAlertLevel={setAlertLevel}
          setOuterAlertMessage={setAlertMessage}
          setOuterAlertIsOpen={setAlertIsOpen}
          datastore={datastore}
        />
      ) : null}
      {generateAddCodeIsOpen ? (
        <AddCodeDialog
          open={generateAddCodeIsOpen}
          setIsOpen={setGenerateAddCodeIsOpen}
          setIsLoading={setIsLoading}
          setOuterAlertLevel={setAlertLevel}
          setOuterAlertMessage={setAlertMessage}
          setOuterAlertIsOpen={setAlertIsOpen}
          datastore={datastore}
        />
      ) : null}
      {editScoutsClassesIsOpen ? (
        <EditScoutsClassesDialog
          editCourseIsOpen={editScoutsClassesIsOpen}
          setEditCourseIsOpen={setEditScoutsClassesIsOpen}
          setIsLoading={setIsLoading}
          setOuterAlertLevel={setAlertLevel}
          setOuterAlertMessage={setAlertMessage}
          setOuterAlertIsOpen={setAlertIsOpen}
          datastore={datastore}
        />
      ) : null}
      {editConfigIsOpen ? (
        <EditConfigDialog
          open={editConfigIsOpen}
          setOpen={setEditConfigIsOpen}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setOuterAlertLevel={setAlertLevel}
          setOuterAlertMessage={setAlertMessage}
          setOuterAlertIsOpen={setAlertIsOpen}
        />
      ) : null}
      {modifyAdminIsOpen ? (
        <ModifyAdminDialog
          open={modifyAdminIsOpen}
          setOpen={setModifyAdminIsOpen}
          datastore={datastore}
          setOuterAlertLevel={setAlertLevel}
          setOuterAlertMessage={setAlertMessage}
          setOuterAlertIsOpen={setAlertIsOpen}
          setIsLoading={setIsLoading}
        />
      ) : null}
      {voidRegistrationIsOpen ? (
        <VoidRegistrationDialog
          open={voidRegistrationIsOpen}
          setOpen={setVoidRegistrationIsOpen}
          datastore={datastore}
          setOuterAlertLevel={setAlertLevel}
          setOuterAlertMessage={setAlertMessage}
          setOuterAlertIsOpen={setAlertIsOpen}
          setIsLoading={setIsLoading}
        />
      ) : null}
      <StandardAlert
        alertIsOpen={alertIsOpen}
        setAlertIsOpen={setAlertIsOpen}
        alertLevel={alertLevel}
        alertMessage={alertMessage}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: "150000 !important" }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
