import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Typography,
  Stack,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import * as React from "react";
import { useEffect, useState } from "react";
import ky from "ky";
import { useLocation, useNavigate } from "react-router-dom";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import AddScoutDialog from "../components/dialogs/AddScoutDialog";
import AddScoutCard from "../components/cards/AddScoutCard";
import ScoutCard from "../components/cards/ScoutCard";
import StandardAlert from "../components/alerts/StandardAlert";

export default function Scouts(props) {
  const {
    datastore,
    setHeaderOpen,
    headerOpen,
    headerHeight,
    setHeaderHeight,
    footerHeight,
    setFooterHeight,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [scouts, setScouts] = useState([]);
  const [addScoutIsOpen, setAddScoutIsOpen] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertLevel, setAlertLevel] = useState("success");

  useEffect(() => {
    ky.get(
      window._env_.REACT_APP_API_URL +
        "/scouts/" +
        datastore.config.configId +
        "/" +
        localStorage.getItem("mb_user_id"),
      {
        timeout: window._env_.REACT_APP_TIMEOUT,
      },
    )
      .json()
      .then((data) => {
        setScouts(data);
        setIsLoading(false);
      })
      .catch((e) => {
        if (e.name === "HTTPError") {
          if (e.response.status === 404) {
            console.log("This user has no participants");
          } else {
            setAlertLevel("error");
            setAlertMessage("Unable to retrieve scouts!");
            setAlertIsOpen(true);
            console.log("Unable to retrieve participants");
          }
        } else {
          setAlertLevel("error");
          setAlertMessage("Unable to retrieve scouts!");
          setAlertIsOpen(true);
          console.log("Unable to retrieve participants");
        }
        setIsLoading(false);
      });
  }, [triggerRefresh]);

  useEffect(() => {
    if (location.state && location.state.registration === "Success") {
      setAlertLevel("success");
      setAlertMessage("Scout successfully registered!");
      setAlertIsOpen(true);
      navigate("/", { state: {}, replace: true });
    }
  }, []);

  return (
    <>
      <Header
        datastore={datastore}
        headerOpen={headerOpen}
        setHeaderOpen={setHeaderOpen}
        headerHeight={headerHeight}
        setHeaderHeight={setHeaderHeight}
      />
      <Box
        sx={{
          flexDirection: "column",
          backgroundColor: "primary.light",
          minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
        }}
        mt={`${headerHeight}px`}
        pb={1}
      >
        <Container sx={{ paddingTop: 4 }}>
          <Typography variant="h4">
            <strong>My Scouts:</strong>
          </Typography>
          <Typography variant="body1">
            <strong>
              View and edit your scout's information and class selections below.
              If no scout's are listed, click the "+" icon to add a new scout
              and get started!
            </strong>
          </Typography>
          {scouts.length === 0 ? (
            <Stack direction="row" justifyContent="center" pt={2}>
              <AddScoutCard
                setAddScoutIsOpen={setAddScoutIsOpen}
                maxWidth={350}
                maxHeight={250}
                scoutNum={scouts.length}
              />
            </Stack>
          ) : (
            <Grid2 container spacing={1} columns={12} pt={2}>
              {scouts.map((scout) => {
                return (
                  <Grid2 key={scout.participantId} lg={3} md={6} sm={6} xs={12}>
                    <ScoutCard
                      scout={scout}
                      setIsLoading={setIsLoading}
                      setTriggerRefresh={setTriggerRefresh}
                      setOuterAlertLevel={setAlertLevel}
                      setOuterAlertMessage={setAlertMessage}
                      setOuterAlertIsOpen={setAlertIsOpen}
                      datastore={datastore}
                    />
                  </Grid2>
                );
              })}
              <Grid2 lg={3} md={6} sm={6} xs={12}>
                <AddScoutCard
                  setAddScoutIsOpen={setAddScoutIsOpen}
                  scoutNum={scouts.length}
                />
              </Grid2>
            </Grid2>
          )}
        </Container>
      </Box>
      <AddScoutDialog
        addScoutIsOpen={addScoutIsOpen}
        setAddScoutIsOpen={setAddScoutIsOpen}
        setIsLoading={setIsLoading}
        setTriggerRefresh={setTriggerRefresh}
        triggerRefresh={triggerRefresh}
        setOuterAlertLevel={setAlertLevel}
        setOuterAlertMessage={setAlertMessage}
        setOuterAlertIsOpen={setAlertIsOpen}
        datastore={datastore}
      />
      <StandardAlert
        alertIsOpen={alertIsOpen}
        setAlertIsOpen={setAlertIsOpen}
        alertLevel={alertLevel}
        alertMessage={alertMessage}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: "150000 !important" }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer footerHeight={footerHeight} setFooterHeight={setFooterHeight} />
    </>
  );
}
