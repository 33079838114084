import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  AccordionSummary,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useNavigate } from "react-router-dom";

import { enableSwipe } from "../../utils/utils";
import StandardAlert from "../alerts/StandardAlert";

const RegistrationInfoDialog = ({
  registrationStatus,
  openState,
  closeState,
  datastore,
  scout,
  pathClicked,
}) => {
  const [bypassCode, setBypassCode] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertLevel, setAlertLevel] = useState("error");

  const navigate = useNavigate();

  const handleClose = () => {
    if (closeState) {
      enableSwipe();
      closeState();
    }
  };

  let message1;
  let message2;
  let title;
  if (registrationStatus === "not-opened") {
    title = "Looks like you're early!";
    message1 = "Our registration has not opened yet.";
    message2 = "Check back on: " + datastore.config.regOpenDate;
  } else if (registrationStatus === "closed") {
    title = "Sorry we missed you!";
    message1 = "Our registration is closed.";
    message2 = "We hope to see you at our next event!";
  }

  return (
    <Dialog
      open={openState}
      maxWidth="xs"
      onClose={handleClose}
      sx={{
        borderRadius: 2,
        "& .MuiDialogTitle-root": {
          display: "flex",
          alignItems: "center",
        },
        "& .MuiDialogTitle-root .MuiTypography-root": {
          flex: 1,
        },
        "& .MuiDialogTitle-root .MuiIconButton-root": {
          marginLeft: "auto",
        },
      }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">{title}</Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{message1}</Typography>
        <Typography variant="body1">{message2}</Typography>
      </DialogContent>
      {datastore.config.regBypassCode && (
        <DialogContent>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body2">
                <strong>Special Exceptions...</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                Enter bypass code below to register.
              </Typography>
              <TextField
                sx={{ mb: 2, mt: 2 }}
                name="bypassCode"
                label="Bypass Code"
                size="small"
                fullWidth
                value={bypassCode}
                onChange={(e) => setBypassCode(e.target.value)}
              />
              <Button
                onClick={() => {
                  if (
                    bypassCode.toLowerCase() ===
                    datastore.config.regBypassCode.toLowerCase()
                  ) {
                    sessionStorage.setItem(
                      "mb_college_scout",
                      JSON.stringify(scout),
                    );
                    navigate(pathClicked);
                  } else {
                    setAlertLevel("error");
                    setAlertMessage("Invalid bypass code!");
                    setAlertIsOpen(true);
                  }
                }}
              >
                Submit
              </Button>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
      )}
      <StandardAlert
        alertIsOpen={alertIsOpen}
        setAlertIsOpen={setAlertIsOpen}
        alertLevel={alertLevel}
        alertMessage={alertMessage}
      />
    </Dialog>
  );
};

export default RegistrationInfoDialog;
