import React from "react";
import MUIDataTable from "mui-datatables";
import {
  CircularProgress,
  Container,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { parsePhoneNumber } from "awesome-phonenumber";

const CounselorTable = (props) => {
  const { datastore, courseJson } = props;

  const columns = [
    {
      name: "courseId",
      label: "Course ID",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "imageUrl",
      label: "Image",
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          if (value) {
            return <img alt="Badge Logo" width="50px" src={value} />;
          } else {
            return "None";
          }
        },
      },
    },
    {
      name: "meritBadgeName",
      label: "Merit Badge Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "startingSession",
      label: "Start Session",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "classDurationInSessions",
      label: "Duration",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "counselorFullName",
      label: "Counselor Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value) {
            return value;
          } else {
            return "None";
          }
        },
      },
    },
    {
      name: "counselorEmailAddress",
      label: "Counselor Email",
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          if (value) {
            return <Link href={"mailto:" + value}>Email</Link>;
          } else {
            return "None";
          }
        },
      },
    },
    {
      name: "counselorPhoneNumber",
      label: "Counselor Phone",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          const pn = parsePhoneNumber(value, { regionCode: "US" });
          if (pn.valid) {
            return (
              <Typography variant="body2" noWrap>
                {pn.number.national}
              </Typography>
            );
          } else {
            return "None";
          }
        },
      },
    },
    {
      name: "maxCapacity",
      label: "Max Capacity",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "spotsRemaining",
      label: "Spots Left",
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: "minAge",
      label: "Minimum Age",
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "prereqs",
      label: "Pre-Reqs",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "isEagle",
      label: "Is Eagle",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value) => {
          if (value) {
            return "Yes";
          } else {
            return "No";
          }
        },
      },
    },
    {
      name: "badgeUrl",
      label: "Workbook",
      options: {
        display: false,
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          if (value) {
            return <Link href={value}>Link</Link>;
          } else {
            return "None";
          }
        },
      },
    },
    {
      name: "addCodes",
      label: "Add Codes",
      options: {
        display: false,
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          if (value && value.length > 0) {
            return "[" + value.join(", ") + "]";
          } else {
            return "None";
          }
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    download: true,
    downloadOptions: {
      filename:
        datastore.config.eventName +
        " Courses and Counselors " +
        datastore.config.eventDate +
        ".csv",
      filterOptions: {
        useDisplayedRowsOnly: true,
        useDisplayedColumnsOnly: true,
      },
    },
    print: false,
    viewColumns: true,
    responsive: "vertical",
    sortThirdClickReset: true,
  };

  return (
    <div>
      {courseJson && courseJson?.status !== 404 ? (
        <MUIDataTable
          title="Classes & Counselors"
          data={courseJson}
          columns={columns}
          options={options}
          style={{ boxShadow: "none !important" }}
        />
      ) : (
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Stack justifyContent="center" alignItems="center" spacing={2}>
            <CircularProgress />

            <Typography variant="h4">Loading Counselors...</Typography>
          </Stack>
        </Container>
      )}
    </div>
  );
};

export default CounselorTable;
