import { Box, Typography, Link, Container, Stack } from "@mui/material";
import * as React from "react";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

export default function Contact(props) {
  const {
    datastore,
    setHeaderOpen,
    headerOpen,
    headerHeight,
    setHeaderHeight,
    footerHeight,
    setFooterHeight,
  } = props;
  return (
    <>
      <Header
        datastore={datastore}
        headerOpen={headerOpen}
        setHeaderOpen={setHeaderOpen}
        headerHeight={headerHeight}
        setHeaderHeight={setHeaderHeight}
      />
      <Box
        sx={{
          flexDirection: "column",
          backgroundColor: "primary.light",
          minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
        }}
        mt={`${headerHeight}px`}
        pb={1}
      >
        <Container>
          <Stack spacing={2} alignItems="center" justifyContent="center">
            <Typography variant="h4" sx={{ paddingTop: 3 }}>
              Contact Us
            </Typography>
            <Typography variant="h5">
              Feel free to reach out to us with any questions or concerns.
            </Typography>
            <Typography variant="body1">
              For <strong>general</strong> questions about our event, use the
              link{" "}
              <Link
                href={
                  "mailto:" +
                  datastore.config.contactEmail +
                  "?subject=Merit Badge Event Inquiry"
                }
              >
                here
              </Link>
              {". "}
            </Typography>{" "}
            <Typography variant="body1">
              For <strong>technical</strong> questions about our website or
              registration, use the link{" "}
              <Link
                href={
                  "mailto:" +
                  datastore.config.contactEmail +
                  "?cc=jacob@adiumtech.com&subject=Merit Badge Event Technical Question"
                }
              >
                here
              </Link>
              {". "}
            </Typography>{" "}
            {datastore.config.counselorInterestIsEnabled === true ? (
              <>
                <Typography variant="h4" sx={{ paddingTop: 3 }}>
                  Interested in being a counselor?
                </Typography>
                <Typography variant="body1">
                  Use the link{" "}
                  <Link
                    href={
                      "mailto:" +
                      datastore.config.contactEmail +
                      "?subject=Merit Badge Event Counselor Interest"
                    }
                  >
                    here
                  </Link>{" "}
                  to shoot us a message and let us know what you are interested
                  in teaching!
                </Typography>
              </>
            ) : null}
            {datastore.config.eventDomain.includes("324") ? (
              <>
                <Typography variant="h4" sx={{ paddingTop: 3 }}>
                  Interested in joining Crew 324?
                </Typography>
                <Typography variant="body1">
                  Check out our website for up to date information on our
                  meeting times and locations{" "}
                  <Link
                    target="_blank"
                    rel="noopener"
                    href="https://www.crew324.com/"
                  >
                    here
                  </Link>
                  {"! "}
                </Typography>
              </>
            ) : null}
          </Stack>
        </Container>
      </Box>
      <Footer footerHeight={footerHeight} setFooterHeight={setFooterHeight} />
    </>
  );
}
