import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import DialogActions from "@mui/material/DialogActions";

import { enableSwipe } from "../../utils/utils";

export default function ReleaseAuthDialog(props) {
  const { releaseAuthIsOpen, setReleaseAuthIsOpen, datastore } = props;

  return (
    <Dialog
      open={releaseAuthIsOpen}
      maxWidth="xs"
      onClose={() => {
        enableSwipe();
        setReleaseAuthIsOpen(false);
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h5" noWrap sx={{ flexGrow: 1 }}>
            <strong>Terms & Conditions</strong>
          </Typography>
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={() => {
                enableSwipe();
                setReleaseAuthIsOpen(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{datastore.config.release}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            enableSwipe();
            setReleaseAuthIsOpen(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
