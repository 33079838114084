import React from "react";
import { Button, Dialog, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { enableSwipe } from "../../utils/utils";

const AudioDialog = (props) => {
  const { datastore, open, setOpen, setAnimationOpen } = props;
  return (
    <Dialog
      open={open}
      fullScreen={false}
      sx={{
        // Set backdrop to same color as animation
        "& .MuiBackdrop-root": {
          backgroundColor: "#6B9B7E",
        },
      }}
    >
      <DialogContent
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <img src="./Venturing.svg" width={125} alt="Event Logo" />
      </DialogContent>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: -3,
        }}
      >
        <Typography variant="h6" align="center">
          Welcome to our {datastore.config.eventName} website!
        </Typography>
      </DialogContent>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: -3,
        }}
      >
        <Typography variant="body1" align="center">
          This website uses sound effects to enhance the user experience. Would
          you like to continue with some awesome sound effects?
        </Typography>
      </DialogContent>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: -4,
        }}
      >
        <Typography variant="caption" align="center">
          (You know you want to!)
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            sessionStorage.setItem("isSound", false);
            enableSwipe();
            setOpen(false);
            setAnimationOpen(true);
          }}
        >
          No Sounds
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            sessionStorage.setItem("isSound", true);
            enableSwipe();
            setOpen(false);
            setAnimationOpen(true);
          }}
        >
          Continue with Sounds
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AudioDialog;
