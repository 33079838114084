import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  Backdrop,
  IconButton,
  InputAdornment,
} from "@mui/material";
import * as React from "react";
import ky from "ky";
import { Link } from "react-router-dom";
import { useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { parsePhoneNumber } from "awesome-phonenumber";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import StandardAlert from "../components/alerts/StandardAlert";
import {
  capitalize,
  disableSwipe,
  isSmallOrMobileDevice,
  isValidEmail,
} from "../utils/utils";
import VerifyEmailDialog from "../components/dialogs/VerifyEmailDialog";

export default function SignUp(props) {
  const {
    datastore,
    setHeaderOpen,
    headerOpen,
    headerHeight,
    setHeaderHeight,
    footerHeight,
    setFooterHeight,
  } = props;

  const defaultValues = {
    configId: datastore.config.configId,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    unitType: "Select",
    unitNumber: "",
    emergencyContactName: "",
    emergencyContactRelation: "",
    emergencyContactPhone: "",
    username: "",
    password: "",
    passwordRetry: "",
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertLevel, setAlertLevel] = useState("error");
  const [backdropIsOpen, setBackdropIsOpen] = useState(false);
  const [showPassword, setShowPassword] = useState("password");
  const [showPasswordCheck, setShowPasswordCheck] = useState("password");
  const [phoneError, setPhoneError] = useState(false);
  const [emergencyPhoneError, setEmergencyPhoneError] = useState(false);
  const [verifyEmailDialogIsOpen, setVerifyEmailDialogIsOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If email field is being updated, validate the email
    if (name === "email") {
      setEmailError(!isValidEmail(value));
    }

    if (name === "firstName" || name === "lastName") {
      setFormValues({
        ...formValues,
        [name]: capitalize(value),
        configId: datastore.config.configId,
      });
    } else if (name === "phone") {
      const pn = parsePhoneNumber(value, { regionCode: "US" });
      if (pn.valid) {
        setPhoneError(false);
      } else {
        setPhoneError(true);
      }
      setFormValues({
        ...formValues,
        [name]: pn.number.national,
      });
    } else if (name === "emergencyContactPhone") {
      const pn = parsePhoneNumber(value, { regionCode: "US" });
      if (pn.valid) {
        setEmergencyPhoneError(false);
      } else {
        setEmergencyPhoneError(true);
      }
      setFormValues({
        ...formValues,
        [name]: pn.number.national,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
        configId: datastore.config.configId,
      });
    }
  };

  return (
    <>
      <Header
        datastore={datastore}
        headerOpen={headerOpen}
        setHeaderOpen={setHeaderOpen}
        headerHeight={headerHeight}
        setHeaderHeight={setHeaderHeight}
      />
      <Box
        sx={{
          flexDirection: "column",
          backgroundColor: "primary.light",
          minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
        }}
        mt={`${headerHeight}px`}
        pb={1}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            flexDirection: "column",
            backgroundColor: "primary.light",
          }}
        >
          <StandardAlert
            alertIsOpen={alertIsOpen}
            setAlertIsOpen={setAlertIsOpen}
            alertLevel={alertLevel}
            alertMessage={alertMessage}
          />
          <Box
            sx={{
              marginTop: 6,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{
                marginTop: isSmallOrMobileDevice() ? 30 : 20,
              }}
              width={isSmallOrMobileDevice() ? 100 : 200}
              src={
                datastore.config.logoFileId
                  ? window._env_.REACT_APP_API_URL +
                    "/getFile/" +
                    datastore.config.configId +
                    "/" +
                    datastore.config.logoFileId
                  : "./bsa.svg"
              }
              alt="logo"
            />
            <Typography variant="h5" pt={2}>
              Create an Account
            </Typography>
            <Typography variant="h6" pt={2}>
              Fill out the form below to create an account.
            </Typography>
            <Typography variant="body1" pt={2}>
              The information entered below should be the parent's info,{" "}
              <strong>NOT</strong> the scout's info. You will be able to
              register multiple scouts under one parent account.
            </Typography>
            <Box
              component="form"
              sx={{
                mt: 1,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                value={formValues.firstName}
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                value={formValues.lastName}
                onChange={handleInputChange}
              />
              <TextField
                error={emailError}
                helperText={emailError ? "Invalid email" : null}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                value={formValues.email}
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Phone Number"
                helperText="Format: (123) 456-7890"
                name="phone"
                value={formValues.phone}
                onChange={handleInputChange}
              />
              <TextField
                select
                margin="normal"
                fullWidth
                SelectProps={{
                  native: true,
                }}
                required
                value={formValues.unitType}
                label="Unit Type"
                name="unitType"
                onChange={handleInputChange}
              >
                <option value="Select" disabled={true}>
                  Select
                </option>
                <option value="Troop">Troop</option>
                <option value="Crew">Crew</option>
                <option value="Ship">Ship</option>
              </TextField>
              <TextField
                required
                margin="normal"
                fullWidth
                id="unit-number-input"
                name="unitNumber"
                label="Unit Number"
                type="number"
                value={formValues.unitNumber}
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="emergency-contact-name-input"
                name="emergencyContactName"
                label="Emergency Contact Name"
                type="text"
                value={formValues.emergencyContactName}
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="emergency-contact-phone-input"
                name="emergencyContactPhone"
                label="Emergency Contact Phone"
                helperText="Format: (123) 456-7890"
                type="text"
                value={formValues.emergencyContactPhone}
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="emergency-contact-relation-input"
                name="emergencyContactRelation"
                label="Emergency Contact Relation"
                type="text"
                value={formValues.emergencyContactRelation}
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                value={formValues.username || null}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                margin="normal"
                required
                name="password"
                label="Password"
                type={showPassword}
                id="password"
                value={formValues.password || null}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          if (showPassword === "password") {
                            setShowPassword("text");
                          } else {
                            setShowPassword("password");
                          }
                        }}
                      >
                        {showPassword === "text" ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                error={passwordError}
                margin="normal"
                required
                name="passwordRetry"
                label="Re-enter Password"
                helperText={
                  passwordError
                    ? "Please make sure both passwords match!"
                    : null
                }
                type={showPasswordCheck}
                id="passwordRetry"
                value={formValues.passwordRetry}
                onChange={(e) => {
                  if (e.target.value === formValues.password) {
                    setPasswordError(false);
                    handleInputChange(e);
                  } else {
                    setPasswordError(true);
                    handleInputChange(e);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          if (showPasswordCheck === "password") {
                            setShowPasswordCheck("text");
                          } else {
                            setShowPasswordCheck("password");
                          }
                        }}
                      >
                        {showPasswordCheck === "text" ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                onClick={() => {
                  // On submit, check if username already exists
                  ky.post(window._env_.REACT_APP_API_URL + "/check-username", {
                    json: formValues,
                    timeout: window._env_.REACT_APP_TIMEOUT,
                  })
                    .json()
                    .then((userExists) => {
                      // Once we got a response, check fields for errors
                      if (emailError) {
                        setAlertLevel("error");
                        setAlertMessage("Please make email address is vaild!");
                        setAlertIsOpen(true);
                      } else if (phoneError) {
                        setAlertLevel("error");
                        setAlertMessage(
                          "Please make sure your phone number is valid!",
                        );
                        setAlertIsOpen(true);
                      } else if (emergencyPhoneError) {
                        setAlertLevel("error");
                        setAlertMessage(
                          "Please make sure emergency contact phone number is valid!",
                        );
                        setAlertIsOpen(true);
                      } else if (userExists) {
                        setAlertLevel("error");
                        setAlertMessage(
                          "A user with the email or username you provided already exists!",
                        );
                        setAlertIsOpen(true);
                      } else if (
                        Object.values(formValues).filter((val) => val === "")
                          .length === 0 &&
                        passwordError === false
                      ) {
                        formValues.configId = datastore.config.configId;
                        setVerifyEmailDialogIsOpen(true);
                        disableSwipe();
                      } else {
                        setAlertLevel("error");
                        setAlertMessage(
                          "Please make sure all fields are populated!",
                        );
                        setAlertIsOpen(true);
                      }
                    })
                    .catch(() => {
                      setAlertLevel("error");
                      setAlertMessage(
                        "Error: Could not check if username already exists.",
                      );
                      setAlertIsOpen(true);
                    });
                }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Create Account
              </Button>

              <Stack justifyContent="center" alignItems="center" spacing={0.5}>
                <Typography variant="body1">
                  <strong>Need Help?</strong>
                </Typography>
                <Link to="/forgotPassword" variant="body2">
                  Forgot Password
                </Link>
                <Link to="/faq" variant="body2">
                  Frequently Asked Questions
                </Link>
                <Link to="/contact" variant="body2">
                  Contact Us
                </Link>
              </Stack>
            </Box>
          </Box>
        </Container>
      </Box>
      {verifyEmailDialogIsOpen ? (
        <VerifyEmailDialog
          isOpen={verifyEmailDialogIsOpen}
          setIsOpen={setVerifyEmailDialogIsOpen}
          datastore={datastore}
          formValues={formValues}
          setBackdropIsOpen={setBackdropIsOpen}
          setAlertIsOpen={setAlertIsOpen}
          setAlertMessage={setAlertMessage}
          setAlertLevel={setAlertLevel}
        />
      ) : null}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={backdropIsOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer footerHeight={footerHeight} setFooterHeight={setFooterHeight} />
    </>
  );
}
