import { Alert, Snackbar } from "@mui/material";
import * as React from "react";

export default function StandardAlert(props) {
  const { alertIsOpen, setAlertIsOpen, alertLevel, alertMessage, width } =
    props;
  return (
    <Snackbar
      sx={{ marginBottom: 5 }}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={alertIsOpen}
      autoHideDuration={6000}
      onClose={() => {
        setAlertIsOpen(false);
      }}
    >
      <Alert
        variant="filled"
        onClose={() => {
          setAlertIsOpen(false);
        }}
        severity={alertLevel}
        sx={{ width: width ? width : "100%" }}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  );
}
