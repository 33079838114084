import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  TextField,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DragHandleIcon from "@mui/icons-material/DragHandle";

const KeyInfoAccordion = ({ config, setConfig }) => {
  const handleKeyInfoChange = (e, index) => {
    const { value } = e.target;
    const updatedKeyInfo = [...config.keyInfo];
    updatedKeyInfo[index] = value;
    setConfig({ ...config, keyInfo: updatedKeyInfo });
  };

  const addKeyInfo = () => {
    const updatedKeyInfo = [...config.keyInfo, ""];
    setConfig({ ...config, keyInfo: updatedKeyInfo });
  };

  const deleteKeyInfo = (index) => {
    const updatedKeyInfo = [...config.keyInfo];
    updatedKeyInfo.splice(index, 1);
    setConfig({ ...config, keyInfo: updatedKeyInfo });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const updatedKeyInfo = [...config.keyInfo];
    const [reorderedItem] = updatedKeyInfo.splice(result.source.index, 1);
    updatedKeyInfo.splice(result.destination.index, 0, reorderedItem);
    setConfig({ ...config, keyInfo: updatedKeyInfo });
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Key Info</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" pb={3}>
            Key info is the information that shows up on the bulleted list on
            the homepage.
          </Typography>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <Box {...provided.droppableProps} ref={provided.innerRef}>
                  {config.keyInfo.map((info, index) => (
                    <Draggable
                      key={index}
                      draggableId={`${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 2,
                          }}
                        >
                          <IconButton {...provided.dragHandleProps}>
                            <DragHandleIcon />
                          </IconButton>
                          <TextField
                            sx={{ mr: 2, flexGrow: 1 }}
                            label={`Info ${index + 1}`}
                            fullWidth
                            value={info}
                            onChange={(e) => handleKeyInfoChange(e, index)}
                          />
                          <IconButton
                            color="error"
                            onClick={() => deleteKeyInfo(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={addKeyInfo}
          >
            Add Info
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default KeyInfoAccordion;
