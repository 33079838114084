import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const AnnouncementBanner = ({ message, onClose }) => {
  return (
    <div
      style={{
        backgroundColor: "#ff9966",
        textAlign: "center",
        display: "flex",
        justifyContent: "space-between", // Changed from flex-end to space-between
        alignItems: "center",
        padding: "10px 10px", // Add some padding to give space for IconButton
      }}
    >
      <div
        style={{
          flex: 1, // Takes all available space
          display: "flex", // New flexbox
          alignItems: "center", // Center vertically
          justifyContent: "center", // Center horizontally
        }}
      >
        <Typography variant="body1" style={{ color: "white" }}>
          <strong>Attention: </strong>
          {message}
        </Typography>
      </div>
      <IconButton onClick={onClose} style={{ marginLeft: "10px" }} size="small">
        <CloseIcon style={{ color: "white" }} fontSize="small" />
      </IconButton>
    </div>
  );
};

export default AnnouncementBanner;
