import { createTheme } from "@mui/material/styles";

// Theme Docs:
// https://colorhunt.co/palette/334257476072548ca8eeeeee

const theme = createTheme({
  palette: {
    primary: {
      light: "#EFFDF3",
      main: "#6B9B7E",
      dark: "#006B3F",
      contrastText: "#E8F3F5",
    },
    secondary: {
      light: "#006B3F",
      main: "#6B9B7E",
      dark: "#EFFDF3",
      contrastText: "#E8F3F5",
    },
    success: {
      main: "#1c912d",
      contrastText: "#F2F2F3",
    },
    warning: {
      main: "#E8A530",
      contrastText: "#F2F2F3",
    },
    error: {
      main: "#C91D1D",
      contrastText: "#F2F2F3",
    },
    black: {
      main: "#000",
      contrastText: "#fff",
    },
    white: {
      main: "#fff",
      contrastText: "#000",
    },
  },
});

export default theme;
