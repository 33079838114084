import { Box, Typography } from "@mui/material";
import React from "react";

import { getBreakpointValues } from "../../utils/utils";

export default function HomeBanner(props) {
  const { datastore } = props;
  return (
    <Box sx={{ flexGrow: 1 }} pt={2} pb={3} maxWidth="98%">
      <Typography align="center">
        <img
          width={getBreakpointValues(150, 250, 300, 350, 400, 400)}
          src={
            datastore.config.logoFileId
              ? window._env_.REACT_APP_API_URL +
                "/getFile/" +
                datastore.config.configId +
                "/" +
                datastore.config.logoFileId
              : "./bsa.svg"
          }
          alt="logo"
        />
      </Typography>
      <Typography
        align="center"
        color="primary.dark"
        sx={{
          padding: 1,
          fontSize: {
            lg: 55,
            md: 40,
            sm: 30,
            xs: 25,
          },
        }}
      >
        {datastore.config.eventDomain.includes("crew324.com")
          ? "Merit Badge College"
          : datastore.config.eventName}
      </Typography>
    </Box>
  );
}
