import { Box, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ky from "ky";
import Grid2 from "@mui/material/Unstable_Grid2";

import BadgeInfoDialog from "../dialogs/BadgeInfoDialog";
import { determineClassTimes, isSmallOrMobileDevice } from "../../utils/utils";
import BadgeCards from "../cards/BadgeCards";

export default function MeritBadgeBanner(props) {
  const { datastore, isLoading, setIsLoading } = props;
  const [firstHalfBadges, setFirstHalfBadges] = useState([]);
  const [secondHalfBadges, setSecondHalfBadges] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState("");

  useEffect(() => {
    const fetchBadges = async () => {
      let badgeData = await ky
        .get(
          window._env_.REACT_APP_API_URL +
            "/courses/" +
            datastore.config.configId,
          {
            timeout: window._env_.REACT_APP_TIMEOUT,
          },
        )
        .json();
      const half = Math.ceil(badgeData.length / 2);
      badgeData = determineClassTimes(badgeData, datastore.config.sessionTimes);
      setFirstHalfBadges(badgeData.slice(0, half));
      setSecondHalfBadges(badgeData.slice(half));
    };

    fetchBadges()
      .then(() => {
        setIsError(false);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsError(true);
        setIsLoading(false);
      });
  }, []);

  return (
    <Box pt={2} pb={5} maxWidth="98%">
      <Container
        sx={{
          paddingBottom: 2,
          textAlign: isSmallOrMobileDevice() ? "center" : "left",
        }}
        maxWidth={false}
      >
        <Typography variant="h4">
          <strong>Merit Badges:</strong>
        </Typography>
      </Container>
      {isLoading ? (
        <Typography variant="h5" pl={3}>
          <strong>Loading Classes...</strong>
        </Typography>
      ) : isError ? (
        <Typography variant="h5" pl={3}>
          <strong>No Classes Found!</strong>
        </Typography>
      ) : (
        <>
          <Grid2 container spacing={2} columns={12} alignItems="start">
            <Grid2
              md={6}
              sm={12}
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <BadgeCards
                badges={firstHalfBadges}
                setIsInfoDialogOpen={setIsInfoDialogOpen}
                setSelectedBadge={setSelectedBadge}
              />
            </Grid2>
            <Grid2
              md={6}
              sm={12}
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <BadgeCards
                badges={secondHalfBadges}
                setIsInfoDialogOpen={setIsInfoDialogOpen}
                setSelectedBadge={setSelectedBadge}
              />
            </Grid2>
          </Grid2>
          <BadgeInfoDialog
            setSelectedBadge={setSelectedBadge}
            selectedBadge={selectedBadge}
            setIsInfoDialogOpen={setIsInfoDialogOpen}
            isInfoDialogOpen={isInfoDialogOpen}
            datastore={datastore}
          />
        </>
      )}
    </Box>
  );
}
