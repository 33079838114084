import React, { useCallback, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";

import useClickOutside from "./useClickOutside";

export const PopoverPicker = ({ color, onChange }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: "28px",
          height: "28px",
          borderRadius: "8px",
          border: "3px solid #fff",
          boxShadow:
            "0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
          backgroundColor: color,
        }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "calc(100% + 2px)",
            borderRadius: "9px",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
            zIndex: 200,
          }}
          ref={popover}
        >
          <HexColorPicker color={color} onChange={onChange} />
        </div>
      )}
    </div>
  );
};
