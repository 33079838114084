import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { Divider } from "@mui/material";

import theme from "../../themes/theme";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#f5f5f5",
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
        }}
      >
        <p>{`Date: ${label}`}</p>
        <p>{`Accumulated Income: $${payload[0].value.toFixed(2)}`}</p>
      </div>
    );
  }
  return null;
};

const NetIncomeChart = ({ data }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const processedData = processData(data);
    setChartData(processedData);
  }, [data]);

  function formatValue(value) {
    const suffixes = ["", "k", "m", "b", "t"]; // suffixes for thousands, millions, billions, trillions
    let suffixIndex = 0;
    while (value >= 1000 && suffixIndex < suffixes.length - 1) {
      value /= 1000;
      suffixIndex++;
    }
    return Math.round(value) + suffixes[suffixIndex];
  }

  function convertDateFormat(dateString) {
    const [month, day, year] = dateString.split("/");
    const formattedYear = year.slice(-2);
    return `${Number(month)}/${Number(day)}/${formattedYear}`;
  }

  const processData = (data) => {
    const incomeByDate = {};

    data.forEach((entry) => {
      // Case 1: Normal registration
      if (
        !entry.isDeleted &&
        entry.netPaymentAmount &&
        entry.initialRegistrationTime
      ) {
        const date = moment.utc(entry.initialRegistrationTime).local();
        if (date.isValid()) {
          const formattedDate = date.format("YYYY-MM-DD");
          if (!incomeByDate[formattedDate]) {
            incomeByDate[formattedDate] = 0;
          }
          incomeByDate[formattedDate] =
            incomeByDate[formattedDate] + parseFloat(entry.netPaymentAmount);
        }
      }
      // Case 2: Registration voided with negative net payment amount, so we use the registration updated time
      else if (
        !entry.isDeleted &&
        entry.netPaymentAmount &&
        !entry.initialRegistrationTime &&
        entry.registrationUpdatedTime
      ) {
        const date = moment.utc(entry.registrationUpdatedTime).local();
        if (date.isValid()) {
          const formattedDate = date.format("YYYY-MM-DD");
          if (!incomeByDate[formattedDate]) {
            incomeByDate[formattedDate] = 0;
          }
          incomeByDate[formattedDate] =
            incomeByDate[formattedDate] + parseFloat(entry.netPaymentAmount);
        }
      }
    });

    const result = [];
    let accumulatedIncome = 0;

    const sortedDates = Object.keys(incomeByDate).sort((a, b) => {
      return moment(a).isBefore(moment(b)) ? -1 : 1;
    });

    sortedDates.forEach((date) => {
      accumulatedIncome += incomeByDate[date];
      result.push({
        date: moment(date).format("MM/DD/YYYY"),
        accumulatedIncome,
      });
    });

    return result;
  };

  return (
    <div style={{ marginBottom: "16px" }}>
      <Accordion defaultExpanded={false} sx={{ borderRadius: "4px" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">
            <strong>Income Graph</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider sx={{ marginBottom: 2.25 }} />
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 0, bottom: 10 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(value) => convertDateFormat(value)}
              />
              <YAxis tickFormatter={(value) => `$${formatValue(value)}`} />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                formatter={(value) => value.replace(/([A-Z])/g, " $1").trim()}
              />
              <Line
                type="monotone"
                dataKey="accumulatedIncome"
                name="Net Income"
                stroke={theme.palette.primary.main}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default NetIncomeChart;
