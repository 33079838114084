import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DeveloperToolsAccordion = ({ config, setConfig }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Developer Settings</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" pb={3}>
            This section is for use by developers only. Setting Maintenance Mode
            to true will trigger a failsafe and take down the website. The
            website can only be re-enabled by a developer.
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.isMaintenance}
                  onChange={(e) =>
                    setConfig({ ...config, isMaintenance: e.target.checked })
                  }
                  name="isMaintenance"
                />
              }
              label="Maintenance Mode"
            />
          </FormGroup>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default DeveloperToolsAccordion;
