import { useState, useEffect } from "react";
import {
  Dialog,
  Box,
  Tooltip,
  Typography,
  Stack,
  IconButton,
  Button,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ky from "ky";
import * as React from "react";

import { enableSwipe } from "../../utils/utils";

const ModifyAdminDialog = ({
  open,
  setOpen,
  datastore,
  setOuterAlertLevel,
  setOuterAlertMessage,
  setOuterAlertIsOpen,
  setIsLoading,
}) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [privilegeLevel, setPrivilegeLevel] = useState("");

  useEffect(() => {
    setIsLoading(true);
    ky.get(
      window._env_.REACT_APP_API_URL + "/users/" + datastore.config.configId,
      {
        timeout: window._env_.REACT_APP_TIMEOUT,
      },
    )
      .json()
      .then((data) => {
        setUsers(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        setOuterAlertLevel("error");
        setOuterAlertMessage(
          "Could not fetch list of users. Please try again!",
        );
        setOuterAlertIsOpen(true);
        setIsLoading(false);
        setOpen(false);
      });
  }, []);

  const handleApplyChanges = () => {
    if (!selectedUser || !privilegeLevel) {
      setOuterAlertLevel("warning");
      setOuterAlertMessage(
        "Please select a user and a privilege level to apply changes.",
      );
      setOuterAlertIsOpen(true);
      return;
    }

    setIsLoading(true);
    let requestBody = {
      username: selectedUser.username,
      isAdmin: false,
      isOwner: false,
      isCounselor: false,
      configId: selectedUser.configId,
    };
    switch (privilegeLevel) {
      case "User":
        requestBody.isAdmin = false;
        requestBody.isOwner = false;
        requestBody.isCounselor = false;
        break;
      case "Counselor":
        requestBody.isAdmin = false;
        requestBody.isOwner = false;
        requestBody.isCounselor = true;
        break;
      case "Admin":
        requestBody.isAdmin = true;
        requestBody.isOwner = false;
        requestBody.isCounselor = false;
        break;
      case "Owner":
        requestBody.isAdmin = false;
        requestBody.isOwner = true;
        requestBody.isCounselor = false;
        break;
      default:
        break;
    }

    ky.patch(window._env_.REACT_APP_API_URL + "/editAdmins", {
      timeout: window._env_.REACT_APP_TIMEOUT,
      json: [requestBody],
    })
      .then(() => {
        setOuterAlertLevel("success");
        setOuterAlertMessage("Privileges updated successfully.");
        setOuterAlertIsOpen(true);
        setIsLoading(false);
        setOpen(false);
        enableSwipe();
      })
      .catch((error) => {
        setOuterAlertLevel("error");
        setOuterAlertMessage("Error applying privilege changes.");
        setOuterAlertIsOpen(true);
        setIsLoading(false);
        console.error("Error applying changes:", error);
        setOpen(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        enableSwipe();
        setOpen(false);
      }}
      maxWidth="sm"
    >
      <Box p={3}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ paddingBottom: "5px" }}
        >
          <Typography variant="h5">
            <strong>Edit Privileges</strong>
          </Typography>
          <Tooltip title="Close" arrow>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Select a user and the privilege level to apply changes.
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          <strong>Privilege Levels:</strong>
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          <strong>User:</strong> The default privilege level for all users. Can
          manage only their own scout's registrations.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          <strong>Counselor:</strong> Has the above privileges as well as can
          view the participant table and counselor table.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          <strong>Admin:</strong> Has the above privileges as well as can
          create, edit, and delete classes and scout's registrations.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          <strong>Owner:</strong> Has the above privileges as well as can modify
          other user's privilege levels and configure the website metadata. Only
          give this privilege to trusted individuals.
        </Typography>
        <Autocomplete
          options={users}
          getOptionLabel={(option) => {
            return `${option.firstName} ${option.lastName} - ID: ${
              option.userId
            } - Level: ${
              option.isOwner
                ? "Owner"
                : option.isAdmin
                  ? "Admin"
                  : option.isCounselor
                    ? "Counselor"
                    : "User"
            }`;
          }}
          sx={{ marginBottom: 2 }}
          onChange={(event, newValue) => {
            setSelectedUser(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select User" variant="outlined" />
          )}
        />
        <FormControl fullWidth>
          <InputLabel id="privilege-level-label">Privilege Level</InputLabel>
          <Select
            labelId="privilege-level-label"
            id="privilege-level"
            value={privilegeLevel}
            label="Privilege Level"
            onChange={(e) => setPrivilegeLevel(e.target.value)}
          >
            <MenuItem value="User">User</MenuItem>
            <MenuItem value="Counselor">Counselor</MenuItem>
            <MenuItem value="Admin">Admin</MenuItem>
            <MenuItem value="Owner">Owner</MenuItem>
          </Select>
        </FormControl>
        <Box textAlign="center" mt={3}>
          <Button variant="contained" onClick={handleApplyChanges}>
            Apply Changes
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ModifyAdminDialog;
