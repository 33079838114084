import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { firstBy } from "thenby";

import { usdFormatter, isValidUrl, enableSwipe } from "../../utils/utils";

export default function BadgeInfoDialog(props) {
  const {
    setSelectedBadge,
    selectedBadge,
    setIsInfoDialogOpen,
    isInfoDialogOpen,
    datastore,
  } = props;
  return (
    <Dialog
      open={isInfoDialogOpen}
      maxWidth="md"
      onClose={() => {
        enableSwipe();
        setSelectedBadge("");
        setIsInfoDialogOpen(false);
      }}
    >
      {selectedBadge && selectedBadge.length > 0 ? (
        <>
          <DialogTitle>
            <Stack
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <img
                src={selectedBadge[0].imageUrl}
                alt={selectedBadge[0].meritBadgeName}
                width={75}
                height={75}
              />
              <Typography variant="h6">
                <strong>{selectedBadge[0].meritBadgeName}</strong>
              </Typography>
            </Stack>
          </DialogTitle>
          <DialogContent>
            {selectedBadge
              .sort(firstBy("startingSession").thenBy("courseId"))
              .map((session, index) => (
                <Box key={index} marginBottom={2}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Stack
                        alignItems="flex-start"
                        justifyContent="center"
                        textAlign="left"
                      >
                        {session.classDurationInSessions > 1 ? (
                          <Typography variant="subtitle1">
                            <strong>
                              Sessions {session.startingSession} -{" "}
                              {session.classDurationInSessions +
                                session.startingSession -
                                1}
                            </strong>
                          </Typography>
                        ) : (
                          <Typography variant="subtitle1">
                            <strong>Session {session.startingSession}</strong>
                          </Typography>
                        )}

                        <Typography variant="subtitle2">
                          Course ID: {session.courseId}
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Typography>
                                <strong>Course ID:</strong>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{session.courseId}</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography>
                                <strong>Class Duration:</strong>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography display="inline">
                                {session.classDurationInSessions
                                  ? session.classDurationInSessions
                                  : "TBD"}{" "}
                              </Typography>
                              <Typography variant="body2" display="inline">
                                Session
                                {session.classDurationInSessions > 1 ? "s" : ""}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography>
                                <strong>Spots Remaining:</strong>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{session.spotsRemaining}</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography>
                                <strong>Class Capacity:</strong>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{session.maxCapacity}</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography>
                                <strong>Minimum Age:</strong>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{session.minAge}</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography>
                                <strong>Prerequisites:</strong>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {session.prereqs ? (
                                isValidUrl(session.prereqs) ? (
                                  <Link
                                    href={session.prereqs}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                  >
                                    Click Here
                                  </Link>
                                ) : (
                                  <Typography>{session.prereqs}</Typography>
                                )
                              ) : (
                                <Typography>TBD</Typography>
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography>
                                <strong>Workbook Link:</strong>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Link
                                href={session.badgeUrl}
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                Click Here
                              </Link>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography>
                                <strong>Additional Fee:</strong>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>
                                {session.additionalCost
                                  ? usdFormatter.format(session.additionalCost)
                                  : "TBD"}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography>
                                <strong>Counselor Name:</strong>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>
                                {session.counselorFirstName
                                  ? session.counselorFirstName +
                                    " " +
                                    session.counselorLastName
                                  : "TBD"}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography>
                                <strong>Counselor's Email:</strong>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {session.counselorEmailAddress ? (
                                <Link
                                  href={
                                    "mailto:" +
                                    session.counselorEmailAddress +
                                    "?cc=" +
                                    datastore.config.contactEmail +
                                    "&subject=" +
                                    datastore.config.eventName +
                                    " " +
                                    session.meritBadgeName +
                                    " Merit Badge Inquiry"
                                  }
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  Click Here
                                </Link>
                              ) : (
                                <Typography>TBD</Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))}
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setSelectedBadge("");
                setIsInfoDialogOpen(false);
                enableSwipe();
              }}
            >
              Close
            </Button>
          </DialogActions>
        </>
      ) : null}
    </Dialog>
  );
}
