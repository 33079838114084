import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const convert12to24 = (time12h) => {
  const [time, modifier] = time12h.split(/(?=[ap]m)/);
  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "pm") {
    hours = parseInt(hours, 10) + 12;
  }

  // Pad hours with zero if it's only one digit.
  hours = hours.toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};

const convert24to12 = (time24h) => {
  let [hours, minutes] = time24h.split(":");

  // Remove padding zero from hours if it exists
  hours = parseInt(hours, 10);

  let modifier = "am";
  if (hours >= 12) {
    modifier = "pm";
    if (hours > 12) {
      hours -= 12;
    }
  } else if (hours === 0) {
    hours = 12;
  }

  return `${hours}:${minutes}${modifier}`;
};

const timeToMinutes = (time12h) => {
  const [time, modifier] = time12h.split(/(?=[ap]m)/);
  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier.toLowerCase() === "pm") {
    hours = parseInt(hours, 10) + 12;
  }

  return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
};

const validateTimes = (sessionTimes) => {
  const sessionOrder = ["session1", "session2", "session3", "session4"];
  let errorMessages = {};

  // Check if each session ends after it starts
  for (let i = 0; i < sessionOrder.length; i++) {
    const startTime = timeToMinutes(sessionTimes[sessionOrder[i]].start);
    const endTime = timeToMinutes(sessionTimes[sessionOrder[i]].end);

    if (startTime >= endTime) {
      errorMessages[sessionOrder[i]] = "End time should be after start time.";
    }
  }

  // Check if each session starts after the previous one ends
  for (let i = 0; i < sessionOrder.length - 1; i++) {
    const currentSessionEnd = timeToMinutes(sessionTimes[sessionOrder[i]].end);
    const nextSessionStart = timeToMinutes(
      sessionTimes[sessionOrder[i + 1]].start,
    );

    if (currentSessionEnd >= nextSessionStart) {
      errorMessages[sessionOrder[i + 1]] = `Session ${
        i + 2
      } should start after Session ${i + 1} ends.`;
    }
  }

  return errorMessages;
};

const SessionTimesAccordion = ({ config, setConfig, onValidationError }) => {
  const [errorMessages, setErrorMessages] = useState({
    session1: "",
    session2: "",
    session3: "",
    session4: "",
  });

  const handleSessionTimeChange = (e, session, field) => {
    const updatedSessionTimes = {
      ...config.sessionTimes,
      [session]: {
        ...config.sessionTimes[session],
        [field]: convert24to12(e.target.value),
      },
    };

    setConfig({
      ...config,
      sessionTimes: updatedSessionTimes,
    });

    const newErrorMessages = validateTimes(updatedSessionTimes);
    setErrorMessages(newErrorMessages);

    // Pass the validity status to the parent component
    onValidationError(Object.keys(newErrorMessages).length > 0);
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Session Times</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" pb={3}>
            This section defines the session times. There can only be 4
            sessions, no more and no less. Each session must occur sequentialy.
            Ex. Session 2 can't start until Session 1 ends.
          </Typography>
          {Object.entries(config.sessionTimes).map(([session, time]) => (
            <Box
              key={session}
              sx={{ mb: 2, display: "flex", alignItems: "center" }}
            >
              <TextField
                sx={{ mr: 2 }}
                name="start"
                label={`Session ${session.charAt(
                  session.length - 1,
                )} Start Time`}
                fullWidth
                type="time"
                helperText={errorMessages[session]}
                error={!!errorMessages[session]}
                inputProps={{
                  step: 1800, // 30 min
                }}
                value={convert12to24(time.start)}
                onChange={(e) => handleSessionTimeChange(e, session, "start")}
              />
              <TextField
                name="end"
                label={`Session ${session.charAt(session.length - 1)} End Time`}
                fullWidth
                type="time"
                helperText={errorMessages[session]}
                error={!!errorMessages[session]}
                inputProps={{
                  step: 1800, // 30 min
                }}
                value={convert12to24(time.end)}
                onChange={(e) => handleSessionTimeChange(e, session, "end")}
              />
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default SessionTimesAccordion;
