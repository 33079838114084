import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Toolbar,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ky from "ky";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { capitalize, enableSwipe } from "../../utils/utils";

export default function EditScoutsClassesDialog(props) {
  const {
    editCourseIsOpen,
    setEditCourseIsOpen,
    setIsLoading,
    datastore,
    setOuterAlertLevel,
    setOuterAlertMessage,
    setOuterAlertIsOpen,
  } = props;
  const navigate = useNavigate();
  const [allScouts, setAllScouts] = useState([]);
  const [selectedScout, setSelectedScout] = useState({
    participantId: "Select",
  });

  useEffect(() => {
    const fetchScouts = () => {
      return ky
        .get(
          window._env_.REACT_APP_API_URL +
            "/participants/" +
            datastore.config.configId,
          {
            timeout: window._env_.REACT_APP_TIMEOUT,
          },
        )
        .json();
    };

    setIsLoading(true);
    fetchScouts()
      .then((scoutData) => {
        setIsLoading(false);
        setAllScouts(scoutData);
      })
      .catch(() => {
        setIsLoading(false);
        setOuterAlertLevel("error");
        setOuterAlertMessage("Error: Could not fetch scouts.");
        setOuterAlertIsOpen(true);
      });
  }, []);

  const handleScoutSelect = (id) => {
    if (id === "Select") {
      setSelectedScout({
        participantId: "Select",
      });
    } else if (id === null) {
      // Case where the user deletes the text, do nothing
    } else {
      const scout = allScouts.find((scout) => scout.participantId === id);
      setSelectedScout(scout);
    }
  };

  return (
    <Dialog
      open={editCourseIsOpen}
      maxWidth="xs"
      onClose={() => {
        enableSwipe();
        setEditCourseIsOpen(false);
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h5" noWrap sx={{ flexGrow: 1 }}>
            <strong>Edit a Scout's Classes</strong>
          </Typography>
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={() => {
                enableSwipe();
                setEditCourseIsOpen(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Select the scout you want to update. Then click the button to be
          redirected to the edit scout page.
        </Typography>
        <Typography variant="body1" sx={{ paddingTop: "10px" }}>
          <strong>NOTE:</strong> The parent and the scout will be notified of
          any updates you make to their schedule! Please be sure to communicate
          with them before making any changes.
        </Typography>
        <Box component="form" sx={{ mt: 1 }}>
          <Autocomplete
            options={[{ participantId: "Select" }, ...allScouts]}
            getOptionLabel={(option) => {
              if (option.participantId === "Select")
                return option.participantId;
              else {
                return `${capitalize(option.firstName)} ${capitalize(
                  option.lastName,
                )} - ${option.unitType}: ${option.unitNumber}`;
              }
            }}
            isOptionEqualToValue={(option, value) => {
              // console.log(option, value);
              return option.participantId === value.participantId;
            }}
            value={selectedScout}
            onChange={(event, newValue) => {
              handleScoutSelect(newValue ? newValue.participantId : null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Scout"
                margin="normal"
                fullWidth
                required
              />
            )}
          />
          {selectedScout.participantId !== "Select" ? (
            <>
              <Toolbar>
                <Typography variant="h6" flexGrow={1} align="center">
                  <strong>{selectedScout.firstName}'s Profile Info</strong>
                </Typography>
              </Toolbar>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                    >
                      <strong>Unit</strong>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                    >
                      {selectedScout.unitType} {selectedScout.unitNumber}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <strong>Birthdate</strong>
                    </TableCell>
                    <TableCell align="center">
                      {selectedScout.birthdate}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <strong>Reg Date</strong>
                    </TableCell>
                    <TableCell align="center">
                      {selectedScout.initialRegistrationTime
                        ? new Date(
                            selectedScout.initialRegistrationTime,
                          ).toLocaleString("en-US", {
                            timeZone: "America/Los_Angeles",
                          })
                        : "Not Registered"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <strong>Payment</strong>
                    </TableCell>
                    <TableCell align="center">
                      {selectedScout.paymentAmount &&
                      selectedScout.paymentAmount > 0
                        ? "$" +
                          selectedScout.paymentAmount +
                          " by " +
                          selectedScout.paymentMethod
                        : "Not Paid"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <strong>Order ID</strong>
                    </TableCell>
                    <TableCell align="center">
                      {selectedScout.orderId ? selectedScout.orderId : "None"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <strong>Email</strong>
                    </TableCell>
                    <TableCell align="center">
                      {selectedScout.participantEmailAddress
                        ? selectedScout.participantEmailAddress
                        : selectedScout.parentEmailAddress}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Button
                onClick={() => {
                  sessionStorage.setItem(
                    "mb_college_scout",
                    JSON.stringify(selectedScout),
                  );
                  enableSwipe();
                  navigate("/edit-registration");
                }}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Update Course Selections
              </Button>
            </>
          ) : null}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
