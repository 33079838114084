import React, { useState, useEffect } from "react";
import { sort } from "fast-sort";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Backdrop,
  Chip,
  TextField,
  Stack,
  Paper,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import ky from "ky";
import Grid2 from "@mui/material/Unstable_Grid2";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import { determineClassTimes, disableSwipe, isUnique } from "../utils/utils";
import BadgeRegisterCard from "../components/cards/BadgeRegisterCard";
import PaymentDialog from "../components/dialogs/PaymentDialog";
import ReleaseAuthDialog from "../components/dialogs/ReleaseAuthDialog";

export default function Register(props) {
  const {
    datastore,
    setHeaderOpen,
    headerOpen,
    headerHeight,
    setHeaderHeight,
    footerHeight,
    setFooterHeight,
  } = props;
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [isReleaseChecked, setIsReleaseChecked] = useState(false);
  const [releaseAuthIsOpen, setReleaseAuthIsOpen] = useState(false);
  const [firstHalfBadges, setFirstHalfBadges] = useState([]);
  const [secondHalfBadges, setSecondHalfBadges] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBadges, setSelectedBadges] = useState([]);
  const [promoCode, setPromo] = useState("");
  const [addCode, setAddCode] = useState("");
  const [verifiedAddCodes, setVerifiedAddCodes] = useState([]);
  const [isOverlap, setIsOverlap] = useState(false);
  const [food, setFood] = useState("");

  const [price, setPrice] = useState(datastore.config.eventPrice);

  useEffect(() => {
    const fetchBadges = () => {
      return ky
        .get(
          window._env_.REACT_APP_API_URL +
            "/courses/" +
            datastore.config.configId,
          {
            timeout: window._env_.REACT_APP_TIMEOUT,
          },
        )
        .json();
    };
    setIsLoading(true);
    fetchBadges().then((badgeData) => {
      const cleanedData = determineClassTimes(
        badgeData,
        datastore.config.sessionTimes,
      );
      if (badgeData.length > 1) {
        const half = Math.ceil(badgeData.length / 2);
        setFirstHalfBadges(cleanedData.slice(0, half));
        setSecondHalfBadges(cleanedData.slice(half));
      } else {
        setFirstHalfBadges(cleanedData);
        setSecondHalfBadges([]);
      }
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    const totalDuration = selectedBadges.reduce((n, { dur }) => n + dur, 0);
    const areStartsUnique = isUnique(selectedBadges, "startingSession");
    const areEndsUnique = isUnique(selectedBadges, "endingSession");
    if (totalDuration > 4) {
      setIsOverlap(true);
      console.log("Uh oh, you've picked more classes than we have time for!");
    } else if (areStartsUnique === false) {
      setIsOverlap(true);
      console.log(
        "Uh oh, you've picked two or more classes that start at the same time!",
      );
    } else if (areEndsUnique === false) {
      setIsOverlap(true);
      console.log(
        "Uh oh, you've picked two or more classes that end at the same time!",
      );
    } else {
      setIsOverlap(false);
    }
  }, [selectedBadges]);

  useEffect(() => {
    if (datastore.config.discounts !== null) {
      for (let i = 0; i < datastore.config.discounts.length; i++) {
        if (datastore.config.discounts[i].promoCode === promoCode) {
          setPrice(datastore.config.discounts[i].price);
          break; // Exit the loop since we found a match
        } else {
          setPrice(datastore.config.eventPrice);
        }
      }
    }
  }, [promoCode]);

  useEffect(() => {
    function validateAddCode() {
      return ky
        .get(
          window._env_.REACT_APP_API_URL +
            "/findAddCode/" +
            datastore.config.configId +
            "/" +
            addCode,
          {
            timeout: window._env_.REACT_APP_TIMEOUT,
          },
        )
        .json();
    }
    validateAddCode()
      .then((data) => {
        setVerifiedAddCodes((prev) => {
          if (prev.findIndex((e) => e === addCode) !== -1) {
            return prev;
          } else {
            return [...prev, addCode];
          }
        });
        setSelectedBadges((prev) => {
          // If course has already been added, don't add it again
          if (prev.findIndex((e) => e.courseId === data.courseId) !== -1) {
            return prev;
          } else {
            return [
              ...prev,
              {
                courseId: data.courseId,
                dur: data.classDurationInSessions,
                endingSession:
                  data.startingSession + data.classDurationInSessions - 1,
                meritBadgeName: data.meritBadgeName,
                startingSession: data.startingSession,
              },
            ];
          }
        });
      })
      .catch(() => {
        console.log("Invalid Add Code");
      });
  }, [addCode]);

  return (
    <>
      <Header
        datastore={datastore}
        headerOpen={headerOpen}
        setHeaderOpen={setHeaderOpen}
        headerHeight={headerHeight}
        setHeaderHeight={setHeaderHeight}
      />
      <Box
        sx={{
          flexDirection: "column",
          backgroundColor: "primary.light",
          minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
        }}
        mt={`${headerHeight}px`}
        pb={1}
      >
        <Container maxWidth="lg">
          <Box pt={3} mb={3}>
            <Typography variant="h4" gutterBottom>
              <strong>
                {
                  JSON.parse(sessionStorage.getItem("mb_college_scout"))
                    .firstName
                }
                {"'s "}
                Registration
              </strong>
            </Typography>
            <Typography variant="body1">
              Click the down arrow on the class you'd like to register for. Then
              select which session you want by clicking the checkbox. If a
              checkbox is grayed out, that means the class is full or you have
              already selected a class for that session.
            </Typography>
          </Box>

          <Grid2 container spacing={2} columns={12} alignItems="start">
            <Grid2
              md={6}
              sm={12}
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Container>
                {firstHalfBadges.map((badge) => {
                  return (
                    <BadgeRegisterCard
                      key={badge[0].meritBadgeName}
                      badge={badge}
                      selectedBadges={selectedBadges}
                      setSelectedBadges={setSelectedBadges}
                      datastore={datastore}
                    />
                  );
                })}
              </Container>
            </Grid2>
            <Grid2
              md={6}
              sm={12}
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Container>
                {secondHalfBadges.map((badge) => {
                  return (
                    <BadgeRegisterCard
                      key={badge[0].meritBadgeName}
                      badge={badge}
                      selectedBadges={selectedBadges}
                      setSelectedBadges={setSelectedBadges}
                      datastore={datastore}
                    />
                  );
                })}
              </Container>
            </Grid2>
          </Grid2>
          <Paper sx={{ marginLeft: 2, marginRight: 2 }}>
            <Container>
              <Grid2 container columns={12}>
                <Grid2 xs={12} sm={6} md={6} lg={3}>
                  <Box
                    mt={3}
                    mb={3}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="h6" gutterBottom>
                      <strong>Enter Add Code:</strong>
                    </Typography>
                    <Box display="flex" flexWrap="wrap" gap={1}>
                      <TextField
                        size="small"
                        label="Add Code"
                        value={addCode}
                        onChange={(e) => {
                          setAddCode(e.target.value);
                        }}
                      />
                    </Box>
                  </Box>
                </Grid2>
                <Grid2 xs={12} sm={6} md={6} lg={3}>
                  <Box
                    mt={3}
                    mb={3}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="h6" gutterBottom>
                      <strong>Selected Classes:</strong>
                    </Typography>
                    <Box display="flex" flexWrap="wrap" gap={1}>
                      {selectedBadges.length === 0 ? (
                        <Typography variant="body1">
                          No classes selected!
                        </Typography>
                      ) : (
                        <Stack spacing={1}>
                          {sort(selectedBadges)
                            .asc("startingSession")
                            .map((cls) => (
                              <div key={cls.meritBadgeName}>
                                <Chip
                                  label={
                                    cls.dur > 1
                                      ? "Sessions " +
                                        cls.startingSession +
                                        " to " +
                                        cls.endingSession +
                                        ": " +
                                        cls.meritBadgeName
                                      : "Session " +
                                        cls.startingSession +
                                        ": " +
                                        cls.meritBadgeName
                                  }
                                  onDelete={() => {
                                    setSelectedBadges((oldArray) => {
                                      return oldArray.filter(
                                        (el) =>
                                          el.meritBadgeName !==
                                          cls.meritBadgeName,
                                      );
                                    });
                                  }}
                                />
                              </div>
                            ))}
                        </Stack>
                      )}
                    </Box>
                  </Box>
                </Grid2>
                <Grid2 xs={12} sm={6} md={6} lg={3}>
                  <Box
                    mt={3}
                    mb={3}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="h6" gutterBottom>
                      <strong>Enter Discount Code:</strong>
                    </Typography>
                    <Box display="flex" flexWrap="wrap" gap={1}>
                      <TextField
                        size="small"
                        label="Promo Code"
                        value={promoCode}
                        onChange={(e) => {
                          setPromo(e.target.value);
                        }}
                      />
                    </Box>
                  </Box>
                </Grid2>
                <Grid2 xs={12} sm={6} md={6} lg={3}>
                  <Box
                    mt={3}
                    mb={3}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography variant="h6" gutterBottom>
                      <strong>Total Price:</strong>
                    </Typography>
                    <Box display="flex" flexWrap="wrap" gap={1}>
                      <Typography variant="body1">${price}.00</Typography>
                    </Box>
                  </Box>
                </Grid2>
                {datastore.config.showRelease && (
                  <Grid2 xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{ pl: 2 }}
                              checked={isReleaseChecked}
                              onChange={(e) =>
                                setIsReleaseChecked(e.target.checked)
                              }
                              name="showRelease"
                            />
                          }
                          label={
                            <>
                              I Accept the{" "}
                              <Link
                                onClick={(e) => {
                                  e.preventDefault();
                                  disableSwipe();
                                  setReleaseAuthIsOpen(true);
                                }}
                              >
                                Terms and Conditions
                              </Link>
                            </>
                          }
                        />
                      </FormGroup>
                    </Box>
                  </Grid2>
                )}
                {datastore.config.foodOptions &&
                  datastore.config.foodOptions.length > 0 && (
                    <Grid2 xs={12}>
                      <Box
                        mb={3}
                        mt={3}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <FormControl
                          sx={{ minWidth: "100px", maxWidth: "275px" }}
                        >
                          <InputLabel id="food-select-label">Food</InputLabel>
                          <Select
                            labelId="food-select-label"
                            id="food-select"
                            value={food}
                            label="Food"
                            required
                            onChange={(e) => setFood(e.target.value)}
                          >
                            {datastore.config.foodOptions.map(
                              (foodOption, index) => (
                                <MenuItem key={index} value={foodOption}>
                                  {foodOption}
                                </MenuItem>
                              ),
                            )}
                          </Select>
                          <FormHelperText>
                            Please select the food you would like to receive at
                            our event!
                          </FormHelperText>
                        </FormControl>
                      </Box>
                    </Grid2>
                  )}
                <Grid2 xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    mb={3}
                    mt={3}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      disabled={
                        selectedBadges.length === 0 ||
                        (datastore.config.showRelease && !isReleaseChecked) ||
                        isOverlap ||
                        (datastore.config.foodOptions &&
                          datastore.config.foodOptions.length > 0 &&
                          !food)
                      }
                      onClick={() => {
                        disableSwipe();
                        setIsPaymentOpen(true);
                      }}
                    >
                      Continue to Payment
                    </Button>
                  </Box>
                </Grid2>
              </Grid2>
            </Container>
          </Paper>
        </Container>
      </Box>
      <Footer footerHeight={footerHeight} setFooterHeight={setFooterHeight} />
      <ReleaseAuthDialog
        releaseAuthIsOpen={releaseAuthIsOpen}
        setReleaseAuthIsOpen={setReleaseAuthIsOpen}
        datastore={datastore}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: "150000 !important" }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PayPalScriptProvider
        options={{
          "client-id": datastore.config.paypalClientId,
        }}
      >
        {isPaymentOpen ? (
          <PaymentDialog
            isOpen={isPaymentOpen}
            setIsOpen={setIsPaymentOpen}
            selectedBadges={selectedBadges}
            addCodes={verifiedAddCodes}
            price={price}
            food={food}
            scout={JSON.parse(sessionStorage.getItem("mb_college_scout"))}
            datastore={datastore}
          />
        ) : null}
      </PayPalScriptProvider>
    </>
  );
}
