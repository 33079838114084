import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ky from "ky";
import * as React from "react";
import {
  Backdrop,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import StandardAlert from "../components/alerts/StandardAlert";

function ResetPasswordPage(props) {
  const {
    datastore,
    setHeaderOpen,
    headerOpen,
    headerHeight,
    setHeaderHeight,
    footerHeight,
    setFooterHeight,
  } = props;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSendResetCode = async () => {
    setIsSubmitting(true);
    ky.get(
      window._env_.REACT_APP_API_URL +
        "/user/initiateReset/" +
        datastore.config.configId +
        "/" +
        email,
      {
        timeout: window._env_.REACT_APP_TIMEOUT,
      },
    )
      .then(() => {
        setIsSubmitting(false);
        setIsDialogOpen(true);
      })
      .catch((e) => {
        if (e.message.includes("404")) {
          setErrorMessage("Username or Email does not exist :(");
        } else {
          setErrorMessage("Unable to connect to password reset server.");
        }
        setIsSubmitting(false);
        console.error(e.message);
        setAlertIsOpen(true);
      });
  };

  const handleResetCodeChange = (event) => {
    setResetCode(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    ky.patch(window._env_.REACT_APP_API_URL + "/user/resetPasword", {
      json: {
        userName: email,
        resetCode,
        newPassword,
        configId: datastore.config.configId,
      },
    })
      .then(() => {
        setIsSubmitting(false);
        navigate("/login");
      })
      .catch(() => {
        setIsSubmitting(false);
        setErrorMessage(
          "Unable to update your password. Please contact us if this happens.",
        );
        setAlertIsOpen(true);
      });
  };

  return (
    <>
      <Header
        datastore={datastore}
        headerOpen={headerOpen}
        setHeaderOpen={setHeaderOpen}
        headerHeight={headerHeight}
        setHeaderHeight={setHeaderHeight}
      />
      <Box
        display="flex"
        alignItems="center"
        sx={{
          flexDirection: "column",
          backgroundColor: "primary.light",
          minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
        }}
        mt={`${headerHeight}px`}
        pb={1}
      >
        <Stack spacing={2} alignItems="center">
          <Typography variant="h5" textAlign="center" pt={2}>
            <strong>Reset Password</strong>
          </Typography>
          <Container>
            <Typography variant="body1" textAlign="center" pt={2}>
              Enter your username or email address below to initiate a password
              reset.
            </Typography>
          </Container>
          <TextField
            label="Email or Username"
            variant="outlined"
            sx={{ width: "250px" }}
            value={email}
            onChange={handleEmailChange}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={!email || isSubmitting}
            onClick={handleSendResetCode}
            sx={{ width: "250px" }}
          >
            Send Reset Code
          </Button>
        </Stack>

        <Dialog open={isDialogOpen}>
          <DialogTitle>
            <strong>Reset Password</strong>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Please enter the code sent to your email address. Check your
              Spam/Junk folder if you do not see our email.
            </Typography>
            <TextField
              label="Reset Code"
              variant="outlined"
              fullWidth
              value={resetCode}
              onChange={handleResetCodeChange}
              sx={{ marginTop: 2 }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={newPassword}
              onChange={handleNewPasswordChange}
            />
            <TextField
              error={passwordError}
              margin="normal"
              required
              fullWidth
              name="passwordRetry"
              label="Re-enter Password"
              helperText={
                passwordError ? "Please make sure both passwords match!" : null
              }
              type="password"
              id="passwordRetry"
              value={confirmPassword}
              onChange={(e) => {
                if (e.target.value === newPassword) {
                  setPasswordError(false);
                  handleConfirmPasswordChange(e);
                } else {
                  setPasswordError(true);
                  handleConfirmPasswordChange(e);
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                !resetCode || !newPassword || !confirmPassword || isSubmitting
              }
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Footer footerHeight={footerHeight} setFooterHeight={setFooterHeight} />
      <StandardAlert
        alertIsOpen={alertIsOpen}
        setAlertIsOpen={setAlertIsOpen}
        alertLevel="error"
        alertMessage={errorMessage}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: "1500 !important" }}
        open={isSubmitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default ResetPasswordPage;
