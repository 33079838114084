import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
} from "recharts";
import {
  Accordion,
  AccordionDetails,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Paper className="custom-tooltip" sx={{ padding: 1 }}>
        <Stack justifyContent="center" alignItems="center">
          <Typography variant="body1" align="center">
            <strong>{`${payload[0].payload.meritBadgeName}`}</strong>
          </Typography>
          <Typography
            variant="body1"
            align="center"
          >{`${payload[0].payload.spotsRemaining}/${payload[0].payload.maxCapacity} spots left`}</Typography>
          <Typography variant="body2" align="center">{`(${
            100 - payload[0].payload.remainingPercentage.toPrecision(2)
          }% full)`}</Typography>
        </Stack>
      </Paper>
    );
  }
  return null;
};

const RemainingSpotsBarChart = ({ data }) => {
  // Generate a consistent but unique color for each course name
  const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).substr(-2);
    }
    return color;
  };

  // Create a color map for each course name
  const colorMap = {};
  if (data) {
    data.forEach((item) => {
      if (!colorMap[item.meritBadgeName]) {
        colorMap[item.meritBadgeName] = stringToColor(item.meritBadgeName);
      }
    });
  }

  // Calculate percentage of remaining spots for each entry
  const dataWithPercentage = data
    ? data.map((item) => ({
        ...item,
        remainingPercentage: (item.spotsRemaining / item.maxCapacity) * 100,
      }))
    : null;

  // Use window.innerWidth to get the viewport width
  const viewportWidth = window.innerWidth;

  // Adjust properties based on screen size
  const angle = viewportWidth < 900 ? -80 : -45;
  const labelSize = viewportWidth < 900 ? 7 : 12;

  if (data) {
    return (
      <div style={{ width: "100%" }}>
        <Accordion defaultExpanded={false} sx={{ borderRadius: "4px" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">
              <strong>Remaining Spots Graph</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              <strong>(The smaller the bar, the better!)</strong>
            </Typography>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={dataWithPercentage}
                margin={{
                  top: 20,
                  right: 30,
                  left: 5,
                  bottom: 40,
                }}
              >
                <Tooltip content={<CustomTooltip />} />
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="meritBadgeName"
                  angle={angle}
                  textAnchor="end"
                  interval={0} // Show all ticks
                  height={100} // Increased height to prevent label overlap
                  tick={{ fontSize: labelSize }}
                />
                <YAxis domain={[0, 100]} tickFormatter={(tick) => `${tick}%`} />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="remainingPercentage"
                  name="Remaining Spots (%)"
                  legendType="none"
                >
                  {dataWithPercentage.map((entry, index) => {
                    return (
                      <Cell
                        key={`cell-${index}`}
                        fill={colorMap[entry.meritBadgeName]}
                      />
                    );
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  } else {
    return null;
  }
};

export default RemainingSpotsBarChart;
