import React, { useEffect, useRef, useState } from "react";
import { Typography, Stack, Box } from "@mui/material"; // Import Box from MUI for handling styles
import mapboxgl from "mapbox-gl";

import "mapbox-gl/dist/mapbox-gl.css"; // Import the Mapbox CS

const Map = ({ location, address }) => {
  const mapContainerRef = useRef(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiamJ5ZXJsaW5lIiwiYSI6ImNsNG9lZGczMDA2cmkzY3BhM3Y3azA3YWoifQ.8BERJ4EbKvQ-cfiOL_jf4w";

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/outdoors-v12",
      center: [location.longitude, location.latitude],
      zoom: 16.5,
      scrollZoom: false, // Disable scroll zooming on the map
    });

    const marker = new mapboxgl.Marker()
      .setLngLat([location.longitude, location.latitude])
      .addTo(map);

    marker.getElement().addEventListener("click", () => {
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        address,
      )}`;
      window.open(googleMapsUrl, "_blank");
    });

    const enableZoom = () => {
      map.scrollZoom.enable();
      setOpen(false); // Close the backdrop when CTRL key is pressed
    };

    const disableZoom = () => {
      map.scrollZoom.disable();
    };

    // Event listeners for CTRL key press
    window.addEventListener("keydown", (event) => {
      if (event.key === "Control") {
        enableZoom();
      }
    });

    window.addEventListener("keyup", (event) => {
      if (event.key === "Control") {
        disableZoom();
      }
    });

    map.on("wheel", function (event) {
      if (!event.originalEvent.ctrlKey) {
        setOpen(true);
      }
    });

    return () => {
      map.remove(); // Clean up the map instance when the component unmounts
    };
  }, [address]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      ref={mapContainerRef}
      style={{
        height: "100%",
        overflow: "hidden",
        position: "relative",
        borderRadius: "5px",
      }}
    >
      {open && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.2em", // Make text larger
          }}
          onClick={handleClose}
        >
          <Stack alignItems="center">
            <Typography variant="body1">
              To zoom, hold down CTRL while scrolling.
            </Typography>
            <Typography variant="body2">
              Click anywhere to close this message
            </Typography>
          </Stack>
        </Box>
      )}
    </div>
  );
};

export default Map;
