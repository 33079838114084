import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  TextField,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const FoodAccordion = ({ config, setConfig }) => {
  const handleFoodOptionChange = (e, index) => {
    const { value } = e.target;
    const foodOptions = config.foodOptions ? [...config.foodOptions] : [];
    foodOptions[index] = value;
    setConfig({ ...config, foodOptions });
  };

  const handleAddFoodOption = () => {
    const foodOptions = [...(config.foodOptions || []), ""];
    setConfig({ ...config, foodOptions });
  };

  const handleDeleteFoodOption = (index) => {
    const foodOptions = config.foodOptions ? [...config.foodOptions] : [];
    foodOptions.splice(index, 1);
    setConfig({ ...config, foodOptions });
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Food Options</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" pb={3}>
            If you decide to allow your participants to choose what food they
            receive at the event, you can define the food options here.
            Participants will choose from these options when they register. If
            you do not want to allow participants to choose their food, leave
            this section blank.
          </Typography>
          <Typography variant="body1" pb={3}>
            The goal of this feature is to allow for a more broad menu while not
            overbuying food.
          </Typography>
          {(config.foodOptions || []).map((foodOptions, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    value={config.foodOptions[index]}
                    onChange={(e) => handleFoodOptionChange(e, index)}
                    helperText={
                      "Option #" +
                      (index + 1) +
                      (index % 2 === 0
                        ? " Ex. Hot Dog w/ Chips, etc."
                        : " Ex. Cheese Pizza, etc.")
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteFoodOption(index)}
                    sx={{ mb: 2.5 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          ))}
          <Button
            variant="outlined"
            onClick={handleAddFoodOption}
            startIcon={<AddCircleIcon />}
            sx={{ mt: 2 }}
          >
            Add Food Option
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default FoodAccordion;
