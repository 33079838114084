import { Box, Container, Typography } from "@mui/material";
import * as React from "react";
import Faq from "react-faq-component";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const config = {
  animate: true,
};

const styles = {
  bgColor: "primary.light",
};
export default function FAQ(props) {
  const {
    datastore,
    setHeaderOpen,
    headerOpen,
    headerHeight,
    setHeaderHeight,
    footerHeight,
    setFooterHeight,
  } = props;
  return (
    <>
      <Header
        datastore={datastore}
        headerOpen={headerOpen}
        setHeaderOpen={setHeaderOpen}
        headerHeight={headerHeight}
        setHeaderHeight={setHeaderHeight}
      />
      <Box
        sx={{
          flexDirection: "column",
          backgroundColor: "primary.light",
          minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
        }}
        mt={`${headerHeight}px`}
        pb={1}
      >
        <Container>
          <Typography variant="h4" sx={{ paddingTop: 3 }}>
            <strong>FAQs</strong>
          </Typography>
          <Faq
            data={{ rows: datastore.config.faqs }}
            styles={styles}
            config={config}
          />
        </Container>
      </Box>
      <Footer footerHeight={footerHeight} setFooterHeight={setFooterHeight} />
    </>
  );
}
