import React from "react";
import { Box, Container, Typography } from "@mui/material";

import ParticipantTable from "../components/table/ParticipantTable";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const Participants = (props) => {
  const {
    datastore,
    setHeaderOpen,
    headerOpen,
    headerHeight,
    setHeaderHeight,
    footerHeight,
    setFooterHeight,
  } = props;
  return (
    <>
      <Header
        datastore={datastore}
        headerOpen={headerOpen}
        setHeaderOpen={setHeaderOpen}
        headerHeight={headerHeight}
        setHeaderHeight={setHeaderHeight}
      />
      <Box
        sx={{
          flexDirection: "column",
          backgroundColor: "primary.light",
          minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
        }}
        mt={`${headerHeight}px`}
        pb={1}
      >
        <Container>
          <Typography align="center" variant="h4" pt={2} pb={1}>
            <strong>Participant Info</strong>
          </Typography>
        </Container>
        <Container maxWidth="xl">
          <ParticipantTable datastore={datastore} />
        </Container>
      </Box>
      <Footer footerHeight={footerHeight} setFooterHeight={setFooterHeight} />
    </>
  );
};
export default Participants;
