import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ReleaseAccordion = ({ config, setConfig, onValidationError }) => {
  const [error, setError] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "release" && value.length > 2500) {
      setError(true);
      onValidationError(true);
    } else {
      setError(false);
      onValidationError(false);
      setConfig({ ...config, [name]: value });
    }
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Release Auth Settings</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" pb={3}>
            This section is used to modify the contents of the release popup
            that each scout sees during checkout. This is optional and can be
            left blank.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ pl: 2 }}
                      checked={config.showRelease}
                      onChange={(e) =>
                        setConfig({
                          ...config,
                          showRelease: e.target.checked,
                        })
                      }
                      name="showRelease"
                    />
                  }
                  label="Enable Release Popup during Checkout"
                />
              </FormGroup>
            </Grid>
            {config.showRelease && (
              <Grid item xs={12}>
                <TextField
                  sx={{ mb: 2 }}
                  name="release"
                  label="Release Statement"
                  fullWidth
                  multiline
                  rows={6}
                  value={config.release}
                  onChange={handleInputChange}
                  helperText={
                    error
                      ? "Exceeded character limit (2500)"
                      : "This is the policy shown to the user during checkout."
                  }
                  error={error}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ReleaseAccordion;
