import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  TextField,
  IconButton,
  Button,
  Typography,
  Grid,
  Link,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DragHandleIcon from "@mui/icons-material/DragHandle";

const EmailAccordion = ({ config, setConfig }) => {
  const handleInputChange = (e) => {
    setConfig({ ...config, [e.target.name]: e.target.value });
  };
  const handleEmailReminderChange = (e, index) => {
    const { value } = e.target;
    const updatedEmailReminders = [...config.emailReminders];
    updatedEmailReminders[index] = value;
    setConfig({ ...config, emailReminders: updatedEmailReminders });
  };

  const addEmailReminder = () => {
    const updatedEmailReminders = [...config.emailReminders, ""];
    setConfig({ ...config, emailReminders: updatedEmailReminders });
  };

  const deleteEmailReminder = (index) => {
    const updatedEmailReminders = [...config.emailReminders];
    updatedEmailReminders.splice(index, 1);
    setConfig({ ...config, emailReminders: updatedEmailReminders });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const updatedEmailReminders = [...config.emailReminders];
    const [reorderedItem] = updatedEmailReminders.splice(
      result.source.index,
      1,
    );
    updatedEmailReminders.splice(result.destination.index, 0, reorderedItem);
    setConfig({ ...config, emailReminders: updatedEmailReminders });
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Email</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" pb={3}>
            Email reminders are pieces of information you want to tell the users
            in their registration email. They show up as a bulleted list at the
            bottom of the email.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required={true}
                sx={{ mb: 2 }}
                name="contactEmail"
                label="Contact Email Address"
                fullWidth
                value={config.contactEmail}
                onChange={handleInputChange}
                helperText="This is the email address that users will send inqueries to. (This can also be the email address that will be used to send receipts.)"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required={true}
                sx={{ mb: 2 }}
                name="receiptEmailAddress"
                label="Receipt Email Address"
                fullWidth
                value={config.receiptEmailAddress}
                onChange={handleInputChange}
                helperText="This is the email address that will be used to send receipts to users when they sign up."
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required={true}
                sx={{ mb: 2 }}
                name="receiptEmailPassword"
                label="Receipt Email Password"
                fullWidth
                value={config.receiptEmailPassword}
                onChange={handleInputChange}
                helperText={
                  <>
                    This is the password for the email account that will be used
                    to send receipts to users.{" "}
                    <Link
                      target="_blank"
                      href="https://support.google.com/accounts/answer/185833?hl=en"
                    >
                      Info for Gmail App Passwords
                    </Link>
                  </>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required={true}
                sx={{ mb: 2 }}
                name="receiptEmailSmtpHost"
                label="Receipt Email SMTP Host"
                fullWidth
                value={config.receiptEmailSmtpHost}
                onChange={handleInputChange}
                helperText={
                  <>
                    This is host URL of your SMTP server. It is usually of the
                    format:{" "}
                    <Typography noWrap variant="caption" display="inline">
                      [smtp or mail].[customdomain].com.
                    </Typography>
                  </>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required={true}
                sx={{ mb: 2 }}
                name="receiptEmailSmtpPort"
                label="Receipt Email SMTP Port"
                type="number"
                fullWidth
                value={config.receiptEmailSmtpPort}
                onChange={handleInputChange}
                helperText="This is the port of your SMTP server. (It is usually 587.)"
              />
            </Grid>
          </Grid>
          <Typography variant="body1" pb={3}>
            Email reminders are pieces of information you want to tell the users
            in their registration email. They show up as a bulleted list at the
            bottom of the email.
          </Typography>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <Box {...provided.droppableProps} ref={provided.innerRef}>
                  {config.emailReminders.map((reminder, index) => (
                    <Draggable
                      key={index}
                      draggableId={`${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 2,
                          }}
                        >
                          <IconButton {...provided.dragHandleProps}>
                            <DragHandleIcon />
                          </IconButton>
                          <TextField
                            sx={{ mr: 2, flexGrow: 1 }}
                            label={`Reminder ${index + 1}`}
                            fullWidth
                            value={reminder}
                            onChange={(e) =>
                              handleEmailReminderChange(e, index)
                            }
                          />
                          <IconButton
                            color="error"
                            onClick={() => deleteEmailReminder(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={addEmailReminder}
          >
            Add Reminder
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default EmailAccordion;
